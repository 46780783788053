import { Box, Dialog, DialogContent, Link, MobileStepper, Stack, Typography, useMediaQuery } from '@mui/material';
import { useCallback, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OutlinedButton from '../../../netcapital-components/OutlinedButton';
import SolidButton from '../../../netcapital-components/SolidButton';
import styles from './styles';
import { useTheme } from '@emotion/react';

const steps = [
  {
    title: 'Welcome to the Questionnaire',
    body: 'This questionnaire includes important questions about your business, most of which are required to be disclosed in your Form C filings with the SEC. Please answer these questions in each section to the best of your ability.',
    image: `${process.env.REACT_APP_CDN_IMAGES}/listings-web/onboarding/01.png`,
  },
  {
    title: 'Getting Started',
    body: 'Navigate between the different sections using the next and back buttons. As you complete sections, the progress bar up top will increase and the forms will display green check marks. Questions that are optional will be indicated.',
    image: `${process.env.REACT_APP_CDN_IMAGES}/listings-web/onboarding/02.png`,
  },
  {
    title: 'Saving Your Work',
    body: 'There’s no need to complete this questionnaire in one go: fill out the questionnaire at your own pace. Make sure you save your work using the button in the upper left corner before leaving the website.',
    image: `${process.env.REACT_APP_CDN_IMAGES}/listings-web/onboarding/03.png`,
  },
  {
    title: 'Getting Help',
    body: 'If you have any questions or concerns about the questionnaire, feel free to reach out to a member of our compliance team.',
    image: `${process.env.REACT_APP_CDN_IMAGES}/listings-web/onboarding/04.png`,
  },
];

const onboardingStorageKey = 'nc_listings_web_has_done_onboarding';
const userHasOnboarded = () => {
  const expirationDate = JSON.parse(localStorage.getItem(onboardingStorageKey))?.expiresOn;
  return Date.now() < expirationDate;
};

export default function OnboardingModal() {
  const [isOpen, setIsOpen] = useState(!userHasOnboarded());
  const [activeStep, setActiveStep] = useState(0);
  
  const handleClickNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);
  
  const handleClickPrev = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);
  
  const handleClose = useCallback(() => {
    const now = Date.now();
    localStorage.setItem(onboardingStorageKey, JSON.stringify({
      viewedOn: now,
      expiresOn: now + 1000*60*60*24*30 // 30 days from now
    }));
    setIsOpen(false);
  }, []);
  
  const nextButton = (
    <SolidButton
      color='secondary'
      sx={styles.NavButton}
      disableElevation
      onClick={handleClickNext}
    >
      <Typography variant='button' sx={styles.ButtonCTA}>Next</Typography>
      <ArrowForwardIcon sx={styles.ArrowForwardIcon} />
    </SolidButton>
  );
  
  const finalButton = (
    <SolidButton
      color='secondary'
      sx={styles.NavButton}
      disableElevation
      onClick={handleClose}
    >
      <Typography variant='button' sx={styles.ButtonCTA}>Start</Typography>
      <ArrowForwardIcon sx={styles.ArrowForwardIcon} />
    </SolidButton>
  );
  
  const prevButton = (
    <OutlinedButton
      color='secondary'
      sx={styles.NavButton}
      disableElevation
      onClick={handleClickPrev}
    >
      <ArrowBackIcon sx={styles.ArrowBackIcon} />
      <Typography variant='button' sx={styles.ButtonCTA}>Previous</Typography>
    </OutlinedButton>
  );
  
  const theme = useTheme();
  const isMaxWidth = useMediaQuery(theme.breakpoints.down('md'));
  const isMaxHeight = useMediaQuery('(max-height: 714px)');
  
  return (
    <Dialog 
      open={isOpen}
      fullScreen={isMaxWidth || isMaxHeight}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <Link onClick={handleClose} sx={styles.Skip}>Skip</Link>
        <Stack spacing={3}>
          <Box component='img' src={steps[activeStep].image} sx={styles.Image} />
          <Typography variant='h1'>{steps[activeStep].title}</Typography>
          <Typography variant='body1'>{steps[activeStep].body}</Typography>
          <MobileStepper
            variant='dots'
            steps={steps.length}
            position='static'
            activeStep={activeStep}
            nextButton={activeStep === steps.length-1 ? finalButton : nextButton}
            backButton={activeStep > 0 ? prevButton : <Box />}
            sx={styles.Steps}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}