import Question, { Answer } from '../../Question';
import { fetchCompanyFormation, fetchCompanyFormationFile, fetchContactInfo } from '../../../_state/application-form';
import { useGetCompanyFormation, useGetContactInfo } from '../../../_actions/application-form';

import { BusinessStructure } from './BusinessStructure';
import { DateOfFormation } from './DateOfFormation';
import FileField from '../FileField';
import Form from '../FormWrapper';
import { Jurisdiction } from './Jurisdiction';
import { TaxIDNumber } from './TaxIDNumber';
import { Typography } from '@mui/material';
import styles from './styles';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

function CompanyFormation() {
  const { listingKey, offerKey } = useParams();
  const { loaded: loadedContactInfo } = useGetContactInfo(listingKey, offerKey);
  const { loaded } = useGetCompanyFormation(listingKey, offerKey);

  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
  const businessStructure = useRecoilValue(fetchCompanyFormation('businessStructure'));

  return (
    <Form title="Company Formation" loaded={loaded && loadedContactInfo}>
      <BusinessStructure doingBusinessAs={doingBusinessAs} />
      <Jurisdiction doingBusinessAs={doingBusinessAs} />
      <DateOfFormation doingBusinessAs={doingBusinessAs} />
      <TaxIDNumber doingBusinessAs={doingBusinessAs} />
      <Typography variant='h2' sx={styles.CertificateFormationTitle}>Certificate of Formation</Typography>
      <Typography variant='body1' sx={styles.CertificateFormationBody}>Upload the formation documents you received from the state in which you registered your company. Some states refer to these documents as Articles of Incorporation and some states call them a Certificate of Incorporation. Please upload the initial formation documents and all amendments to those documents. If you are unable to find this document, check with the company that filed your initial paperwork, with your attorney, or by searching the Secretary of State website in the state where you are incorporated.</Typography>
      {businessStructure === 'LLC' && (
        <Question>
          Upload your Certificate of Formation
          <Answer>
            <FileField usage='CERTIFICATE_OF_FORMATION' state={fetchCompanyFormationFile} />
          </Answer>
        </Question>
      )}
      {businessStructure === 'CORPORATION' && (
        <Question>
          Upload your Certificate of Incorporation
          <Answer>
            <FileField usage='CERTIFICATE_OF_INCORPORATION' state={fetchCompanyFormationFile} />
          </Answer>
        </Question>
      )}
      {!businessStructure && (
        <Typography variant='error'>To determine which formation document we need, please select your company’s legal structure above.</Typography>
      )}
    </Form>
  );
}

export default CompanyFormation;
