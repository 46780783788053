import { HELP, RADIO_OPTIONS } from '../../../static-info';
import Question, { Answer } from '../../Question';
import { fetchContactInfo, fetchFundingHistory } from '../../../_state/application-form';
import { useRecoilState, useRecoilValue } from 'recoil';

import { FUNDING_HISTORY } from '../../../static-info/listing-application-forms';
import Help from '../../../netcapital-components/Help';
import RadioGroup from '../../../netcapital-components/RadioGroup';
import TextField from '../../../netcapital-components/TextField';
import styles from './styles';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';

export default function OtherCrowdfundingHistory() {
  const [hasOtherCrowdfunding, setHasOtherCrowdfunding] = useRecoilState(fetchFundingHistory('hasOtherCrowdfunding'));
  const savingState_hasOtherCrowdfunding = useAutoSave(FUNDING_HISTORY, 'hasOtherCrowdfunding', hasOtherCrowdfunding);
  const handleChangeHasOtherCrowdfunding = useCallback((e) => setHasOtherCrowdfunding(e.target.value === 'true'), [setHasOtherCrowdfunding]);
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
  const [otherCrowdfundingNames, setOtherCrowdfundingNames] = useRecoilState(fetchFundingHistory('otherCrowdfundingNames'));
  const savingState_OtherCrowdfundingNames = useAutoSave(FUNDING_HISTORY, 'otherCrowdfundingNames', otherCrowdfundingNames);
  const handleChangeOtherCrowdfundingNames = useCallback((e) => setOtherCrowdfundingNames(e.target.value), [setOtherCrowdfundingNames]);
  
  return (
    <>
      <Question>
        Is {doingBusinessAs || 'your company'} currently raising capital on a <Help explanation={HELP['Funding Portal']}>funding portal</Help> other than Netcapital?
        <Answer>
          <RadioGroup
            value={hasOtherCrowdfunding}
            onChange={handleChangeHasOtherCrowdfunding}
            options={RADIO_OPTIONS.YES_NO}
            error={savingState_hasOtherCrowdfunding && savingState_hasOtherCrowdfunding.state === 'error'}
          />
        </Answer>
      </Question>
      {hasOtherCrowdfunding ? 
        <Question>
          Please list all other funding portals {doingBusinessAs || 'your company'} is currently raising capital on.
          <Answer>
            <TextField
              multiline
              onChange={handleChangeOtherCrowdfundingNames}
              placeholder='Please list all other funding portals here'
              rows={10}
              sx={styles.OtherCrowdfundingNamesTextarea}
              value={otherCrowdfundingNames}
              error={savingState_OtherCrowdfundingNames && savingState_OtherCrowdfundingNames.state === 'error'}
            />
          </Answer>
        </Question>
        : 
        null
      }
    </>
  );
}