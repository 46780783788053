import FacebookURL from './FacebookUrl';
import Form from '../FormWrapper';
import LinkedInURL from './LinkedInUrl';
import TwitterURL from './TwitterUrl';
import { useGetSocialMedia } from '../../../_actions/application-form/social-media.actions';
import { useParams } from 'react-router-dom';

function SocialMedia() {
  const {listingKey, offerKey} = useParams();
  const { loaded } = useGetSocialMedia(listingKey, offerKey);

  return (
    <Form title="Social Media" loaded={loaded}>
      <LinkedInURL />
      <TwitterURL />
      <FacebookURL />
    </Form>
  );
}

export default SocialMedia;
