import Question, { Answer } from '../../Question';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import NumberInput from '../../../netcapital-components/NumberInput';
import { SHARES } from '../../../static-info/listing-application-forms';
import { fetchShares } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export default function SecuritiesAsPortalFee({ readOnly }){
  const [portalFeeSecurities, setPortalFeeSecurities] = useRecoilState(fetchShares('portalFeeSecurities'));
  const savingState = useAutoSave(SHARES, 'portalFeeSecurities', portalFeeSecurities);

  const onChangePortalFeeSecurities = useCallback((value) => {
    setPortalFeeSecurities(value);
  },[setPortalFeeSecurities]);
  
  return (
    <Question>
      Securities as Portal Fee
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='bottom'
      >
        <NumberInput 
          onChange={onChangePortalFeeSecurities}
          disabled={readOnly}
          placeholder='0'
          value={portalFeeSecurities}
          error={savingState && savingState.state === 'error'}
          isBoldFocus={false}
          ownerState={{ subVariant: 'admin' }}
        />
      </Answer>
    </Question>
  );
}