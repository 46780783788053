import { Cell, EditField, Row } from '../../../../netcapital-components/Table';

import DateSelector from '../../../../netcapital-components/DateSelector';
import { KEY_PERSON } from '../../../../static-info/listing-application-forms';
import Loading from '../../../Loading';
import { useAutoSave } from '../../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useDeleteEmploymentRecord } from '../../../../_actions/application-form';
import { useParams } from 'react-router-dom';

export default function EmploymentRecordsRow({ employmentRecord, index, onChange }) {
  const { key: personKey, listingKey, offerKey } = useParams();
  const { destroy, loading } = useDeleteEmploymentRecord(listingKey, offerKey, personKey, employmentRecord.key);
  const deleteEmploymentRecord = useCallback(destroy, [destroy]);

  const savingState = useAutoSave(KEY_PERSON, 'ALL', employmentRecord, personKey, `employment-records/${employmentRecord.key}`);

  const onChangeDateStart = useCallback((date) => onChange(index, 'dateStart', date), [onChange, index]);
  const onChangeDateEnd = useCallback((date) => onChange(index, 'dateEnd', date), [onChange, index]);
  const onChangeCompany = useCallback((e) => onChange(index, 'company', e.target.value), [onChange, index]);
  const onChangeRole = useCallback((e) => onChange(index, 'role', e.target.value), [onChange, index]);

  return (
    <>
      <Loading loading={loading} />
      <Row onClickRemove={deleteEmploymentRecord} error={savingState && savingState.state === 'error'}>
        <Cell>
          <DateSelector
            onChange={onChangeDateStart}
            maxDate={new Date()}
            value={employmentRecord.dateStart || ''}
          />
        </Cell>
        <Cell>
          <DateSelector
            onChange={onChangeDateEnd}
            maxDate={new Date()}
            value={employmentRecord.dateEnd || ''}
          />
        </Cell>
        <Cell>
          <EditField
            value={employmentRecord.company || ''}
            onChange={onChangeCompany}
            placeholder="Company"
          />
        </Cell>
        <Cell>
          <EditField
            value={employmentRecord.role || ''}
            onChange={onChangeRole}
            placeholder="Role"
          />
        </Cell>
      </Row>
    </>

  );
}
