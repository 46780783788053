import { Box, IconButton, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { saveStatusState } from '../../../_state/application-form';
import styles from './styles';
import theme from '../../../theme';
import { useAlertActions } from '../../../_actions';
import { useRecoilValue } from 'recoil';

function diffMinutes(date1, date2) {
  const d1 = new Date(date1).getTime();
  const d2 = new Date(date2).getTime();

  return Math.round((d2 - d1) / 60000);
}

export default function AutoSaveStatus() {
  const saveStatus = useRecoilValue(saveStatusState);
  const [lastSaved, setLastSaved] = useState(null);
  const alertActions = useAlertActions();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const openError = useCallback(() => alertActions.error(`An error occurred in ${saveStatus.formId}`, saveStatus.error.details.response, saveStatus.error.details.message, saveStatus.error.details.request), [saveStatus.formId, saveStatus.error]);

  useEffect(() => {
    if (saveStatus?.lastSaved) {
      const intervalId = setInterval(() => {
        const diff = diffMinutes(saveStatus.lastSaved, new Date());
        setLastSaved(diff);
      }, 1000);
  
      return () => clearInterval(intervalId);
    }

    return () => null;
  }, [saveStatus]);
  
  const styling = {
    'SAVED': styles.SavingStatus_SUCCESS,
    'UNSAVED': styles.SavingStatus_INFO,
    'SAVING': styles.SavingStatus_INFO,
    'ERROR': styles.SavingStatus_ERROR,
  };

  if (!saveStatus.msg) return null;

  return (
    <Box sx={styles.SavingStatusWrap}>
      <Box sx={styling[saveStatus.status]}>
        <Typography 
          variant='caption'
          sx={[styles.StatusMessage, { color: saveStatus.status === 'ERROR' ? theme.palette.error.main : theme.palette.common.black }]}
        >
          {saveStatus.msg || 'You have unsaved changes.'}
          {saveStatus.error && (
            <IconButton onClick={openError} sx={styles.ErrorIcon}>
              <KeyboardArrowRightIcon />
            </IconButton>
          )}
        </Typography>
        <Typography sx={styles.TimeStatusMessage} color='InfoText' variant='caption'>
          Last saved <b>{lastSaved}</b> minutes ago
        </Typography>
      </Box>
    </Box>
  );
}