const styles = {
  CellBoxNested: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    'span:first-of-type': {
      marginRight: '20px'
    }
  },
  CellHeaderOverride: ({breakpoints}) => ({
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    [breakpoints.up('md')]: {
      fontSize: '16px',
      lineHeight: '22px',
    }
  }),
  CellSpan: ({ breakpoints }) => ({
    fontSize: '13px',
    [breakpoints.up('lg')]: {
      fontSize: '15px',
    },
  }),
  HelpContainer: {
    marginBottom: '15px',
  },
  Subtitle: ({ palette }) => ({
    '&.MuiTypography-root': {
      marginTop: '1rem',
    },
    color: palette.primary.dark
  }),
  UseOfProceedsTableSizes:{
    Proceed: {
      width: '130px'
    },
    AmountMinHeader: {
      width: '150px'
    }
  },
  NotEditable: {
    cursor: 'default',
    userSelect: 'none',
  },
};

export default styles;