import Question, { Answer } from '../../Question';

import FileField from '../FileField';
import { fetchFinancialStatementsFile } from '../../../_state/application-form';

function ExecutiveCertification(){
  return (
    <Question>
      Upload your Principal Executive Certification
      <Answer>
        <FileField usage='EXECUTIVE_CERTIFICATION' state={fetchFinancialStatementsFile} />
      </Answer>
    </Question>
  );
}

export default ExecutiveCertification;