import { Box, Typography } from '@mui/material';
import Question, { Answer, Description } from '../../Question';

import Form from '../FormWrapper';
import { HELP } from '../../../static-info';
import Help from '../../../netcapital-components/Help';
import Purpose from './Purpose';
import UseOfProceedsTable from './useOfProceedsTable/index';
import styles from './styles';
import { useGetUseOfProceeds } from '../../../_actions/application-form/use-of-proceeds.actions';
import { useParams } from 'react-router-dom';

function UseOfProceeds() {
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetUseOfProceeds(listingKey, offerKey);
  
  return (
    <Form title='Use of Proceeds' loaded={loaded}>
      <Typography variant='h2' sx={styles.Subtitle}>How will you use the funds you raise in this offering?</Typography>
      <Question>
        Provide a general statement describing how you’ll use the funds you raise.
        <Description>
          You should discuss each probable use and the factors your company may consider in allocating proceeds.
        </Description>
        <Answer>
          <Purpose />
        </Answer>
      </Question>
      <Question>
        Provide a detailed breakdown of how you’ll allocate the funds you raise.
        <Description>Create additional rows for any big expenses like real estate, expensive equipment, repayment of an outstanding debt, etc.
          <br /><br />
        </Description>
        <Box sx={styles.HelpContainer}>
          <Help explanation={HELP['Use of Proceeds Table Instructions']}>How do I complete this table?</Help>
        </Box>
        {loaded ? <UseOfProceedsTable /> : null}
      </Question>
    </Form>
  );
}

export default UseOfProceeds;
