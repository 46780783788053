const styles = {
  Link: ({ palette }) => ({
    color: palette.primary.dark,
  }),
  Paragraph: ({ palette }) => ({
    color: palette.primary.dark,
  }),
  Subtitle: ({breakpoints}) => ({
    fontSize: '20px',
    '&.MuiTypography-h2': {
      marginBottom: '15px',
    },
    [breakpoints.up('lg')]: {
      fontSize: '40px',
    },
  }),
  SignatureRow: ({breakpoints}) => ({
    margin: 0,
    [breakpoints.up('xs')]: {
      '& > *': {
        marginBottom: '1rem !important',
        width: '100%',
      },
    },
    [breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      top: '-16px',
      '& > *': {
        marginBottom: '0 !important',
        marginLeft: '1rem !important',
        marginTop: '3px !important',
        width: '36% !important',
      },
    },
  }),
  SignatureRowBoardLabel: ({ breakpoints }) => ({
    [breakpoints.up('sm')]: {
      marginLeft: '0 !important',
      width: '28% !important',
    }
  }),
  SignatureRowExecutiveLabel: ({ breakpoints }) => ({
    [breakpoints.up('sm')]: {
      marginLeft: '0 !important',
      width: '36% !important',
    }
  }),
};

export default styles;