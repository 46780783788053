const styles = {
  Explain: ({ palette }) => ({
    fontWeight: 600,
    cursor: 'pointer',
    color: palette.primary.dark,
  }),
  HelpText: () => ({
    padding: '20px',
    width: '300px',
  }),
};

export default styles;