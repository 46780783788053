import Question, { Answer } from '../../Question';

import { COMPANY_FORMATION } from '../../../static-info/listing-application-forms';
import { LOCATIONS } from '../../../static-info';
import Selectbox from '../../../netcapital-components/Selectbox';
import { fetchCompanyFormation } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function Jurisdiction({ doingBusinessAs }) {
  const [jurisdiction, setJurisdiction] = useRecoilState(fetchCompanyFormation('jurisdiction'));
  const savingState = useAutoSave(COMPANY_FORMATION, 'jurisdiction', jurisdiction);
  const handleJurisdiction = useCallback(e => setJurisdiction(e.target.value), [setJurisdiction]);
  
  return (
    <Question>
      In which jurisdiction is {doingBusinessAs || 'your company'} legally formed?
      <Answer>
        <Selectbox
          onChange={handleJurisdiction}
          options={LOCATIONS.US_STATES.concat(LOCATIONS.US_JURISDICTIONS)}
          placeholder='Jurisdiction'
          value={jurisdiction}
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}