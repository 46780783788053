import { Route, Routes } from 'react-router-dom';
import { withProfiler as withSentryProfiler, withSentryReactRouterV6Routing } from '@sentry/react';

import { Alert } from './components/Alert';
import AppContainer from './layouts/AppContainer';
import ApplicationForm from './layouts/ApplicationForm';
import CssBaseline from '@mui/material/CssBaseline';
// import IssuerDashboard from './pages/IssuerDashboard';
import NoPermissionsPage from './pages/Error/NoPermissions';
import NotFoundPage from './pages/Error/NotFound';
import Questionnaires from './pages/Questionnaires';
import { ThemeProvider } from '@mui/material/styles';
import { appGlobalSettingsState } from './_state';
import theme from './theme';
import { useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRecoilState } from 'recoil';

function App() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const [appGlobalSettings, setAppGlobalSettings] = useRecoilState(appGlobalSettingsState);
  const SentryRoutes = withSentryReactRouterV6Routing(Routes);
  
  // Set global settings state values
  useEffect(() => {
    setAppGlobalSettings({...appGlobalSettings, isMobile, isTablet});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isMobile, isTablet]);
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Alert />
      <SentryRoutes>
        {/* To-do: Re-enable when implementing Issuer Dashboard */}
        <Route path='/' element={<AppContainer />}>
          {/* <Route path='/dashboard' element={<IssuerDashboard />} /> */}
          <Route path='/admin/questionnaires' element={<Questionnaires />} />
          <Route path='listings/:listingKey/offers/:offerKey/*' element={<ApplicationForm />} />
          <Route path='/no-permission' element={<NoPermissionsPage />} />
          <Route index element={<NotFoundPage />} /> {/* remove this when un-commenting other routes for dashboard */}
          {/* <Route index element={<Navigate to='/dashboard' replace />} /> */}
          <Route path='*' element={<NotFoundPage />} />
        </Route>
      </SentryRoutes>
    </ThemeProvider>
  );
}

export default withSentryProfiler(App);
