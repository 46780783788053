import Question, { Answer } from '../../Question';
import { agreementsState, fetchContactInfo, fetchFundingHistory } from '../../../_state/application-form';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { FUNDING_HISTORY } from '../../../static-info/listing-application-forms';
import { RADIO_OPTIONS } from '../../../static-info';
import RadioGroup from '../../../netcapital-components/RadioGroup';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';

export default function HasLifetimeCrowdfundingHistory() {
  const [hasLifetimeCrowdfundingHistory, setHasLifetimeCrowdfundingHistory] = useRecoilState(fetchFundingHistory('hasLifetimeCrowdfundingHistory'));
  const savingState_HasLifetimeCrowdfundingHistory = useAutoSave(FUNDING_HISTORY, 'hasLifetimeCrowdfundingHistory', hasLifetimeCrowdfundingHistory);
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
  const resetAgreements = useResetRecoilState(agreementsState);
  const handleChangeHasLifetimeCrowdfundingHistory = useCallback((e) => {
    setHasLifetimeCrowdfundingHistory(e.target.value === 'true');
    resetAgreements();
  },[resetAgreements, setHasLifetimeCrowdfundingHistory]);
  
  return (
    <Question>
      Has {doingBusinessAs || 'your company'} ever conducted a Reg CF raise?
      <Answer>
        <RadioGroup
          value={hasLifetimeCrowdfundingHistory}
          onChange={handleChangeHasLifetimeCrowdfundingHistory}
          options={RADIO_OPTIONS.YES_NO}
          error={savingState_HasLifetimeCrowdfundingHistory && savingState_HasLifetimeCrowdfundingHistory.state === 'error'}
        />
      </Answer>
    </Question>
  );
}