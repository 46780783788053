import { Cell, EditField, Row } from '../../../../netcapital-components/Table';
import { useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '../../../../netcapital-components/Checkbox';
import DateSelector from '../../../../netcapital-components/DateSelector';
import { Link } from '@mui/material';
import ModalInput from '../../../../netcapital-components/ModalInput';
import MoneyInput from '../../../../netcapital-components/MoneyInput';
import PercentInput from '../../../../netcapital-components/PercentInput';
import PropTypes from 'prop-types';
import styles from '../styles';

function CreditorsRow({ creditor, index, onChange, onRemove }){
  const [openTerms, setOpenTerms] = useState(false);
  const toggleTerms = useCallback(() => setOpenTerms(!openTerms), [openTerms, setOpenTerms]);
  const handleRemoveRow = useCallback(() => onRemove(index), [index, onRemove]);
  const handleChangeCreditorName = useCallback(e => onChange(index, { name: e.target.value }), [index, onChange]);
  const handleTermsChange = useCallback(value => onChange(index, { terms: value }), [index, onChange]);
  const handleChangeBalance = useCallback(value => onChange(index, { balance: value }), [index, onChange]);
  const handleChangeRate = useCallback(value => onChange(index, { rate: value }), [index, onChange]);
  const handleChangeIsPayableOnDemand = useCallback(e => onChange(index, { isPayableOnDemand: e.target.checked }), [index, onChange]);
  const handleChangeNoMaturityDate = useCallback(e => onChange(index, { noMaturityDate: e.target.checked }), [index, onChange]);
  const handleChangeMaturityDate = useCallback(value => onChange(index, { maturityDate: value }), [index, onChange]);
  
  return (
    <Row onClickRemove={handleRemoveRow}>
      <Cell>
        <EditField 
          value={creditor.name} 
          type='text' 
          placeholder='Creditor name'
          onChange={handleChangeCreditorName} 
          sx={styles.CreditorTableSizes.Name} 
        />
      </Cell>
      <Cell>
        <Box sx={styles.CreditorTableSizes.Balance}>
          <MoneyInput
            value={creditor.balance}
            onChange={handleChangeBalance}
            variant='standard'
            isBoldFocus
          />
        </Box>
      </Cell>
      <Cell>
        <PercentInput 
          value={creditor.rate} 
          valueSavedAs='percentage'
          onChange={handleChangeRate} 
          variant='standard'
          isBoldFocus
          sx={styles.CreditorTableSizes.Rate}
        />
      </Cell>
      <Cell>
        <Box sx={styles.CreditorTableSizes.MaturityDate}>
          <DateSelector 
            onChange={handleChangeMaturityDate}
            size='small' 
            sx={styles.DateSelector}
            value={creditor.maturityDate || ''}
          />
          <Checkbox 
            sx={styles.Checkbox} 
            label='Payable on demand' 
            checked={creditor.isPayableOnDemand || false}
            onChange={handleChangeIsPayableOnDemand} 
          />
          <Checkbox 
            sx={styles.Checkbox} 
            label='No maturity date' 
            checked={creditor.noMaturityDate || false}
            onChange={handleChangeNoMaturityDate} 
          />
        </Box>
      </Cell>
      <Cell>
        <Box sx={styles.CreditorTableSizes.Terms}>
          <Link 
            onClick={toggleTerms} 
            sx={styles.HyperLink}
          >
            {creditor.terms === '' ? '+ Add Terms' : 'View Terms'}
          </Link>
          <ModalInput
            onClose={toggleTerms}
            open={openTerms}
            value={creditor.terms || ''}
            onChange={handleTermsChange}
            title='Terms:'
          />
        </Box>
      </Cell>
    </Row>
  );
}

export default CreditorsRow;

CreditorsRow.defaultProps = {
  creditor: {
    balance: 0,
    isPayableOnDemand: false,
    maturityDate: '',
    name: '',
    noMaturityDate: false,
    rate: 0,
    terms: '',
  },
  index: 0,
  onChange: () => { return; },
  onRemove: () => { return; }
};

CreditorsRow.propTypes = {
  creditor: PropTypes.shape({
    balance: PropTypes.number,
    isPayableOnDemand: PropTypes.bool,
    maturityDate: PropTypes.string,
    name: PropTypes.string,
    noMaturityDate: PropTypes.bool,
    rate: PropTypes.number,
    terms: PropTypes.string
  }),
  index: PropTypes.number,
  onChange: PropTypes.func,
  onRemove: PropTypes.func
};