import { Cell, Header } from '../../../../netcapital-components/Table';

export default function EmploymentRecordsHeader() {
  return (
    <Header>
      <Cell>Start Date</Cell>
      <Cell>End Date</Cell>
      <Cell>Company</Cell>
      <Cell>Position &#47; Title</Cell>
    </Header>
  );
}
