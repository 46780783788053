import { Cell, Row } from '../../../../netcapital-components/Table';
import { useCallback, useState } from 'react';

import ModalInput from '../../../../netcapital-components/ModalInput';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import styles from './styles';

function AdditionalRisk({ onClickRemove, onChange, value, defaultValue }) {
  const [openRiskModal, setOpenRiskModal] = useState(false);
  const toggleRiskModal = useCallback(() => setOpenRiskModal(!openRiskModal), [openRiskModal, setOpenRiskModal]);
  
  return (
    <Row
      onClickRemove={onClickRemove}
      onClickEdit={toggleRiskModal}
    >
      <Cell>
        <Typography onClick={toggleRiskModal} sx={styles.ModalToggle}>{value}</Typography>
        <ModalInput 
          onClose={toggleRiskModal}
          open={openRiskModal}
          value={value || ''}
          onChange={onChange}
          title='Risk:'
          defaultValue={defaultValue}
        />
      </Cell>
    </Row>
  );
}

AdditionalRisk.defaultProps = {
  defaultValue: '',
};

AdditionalRisk.propTypes = {
  onClickRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

export default AdditionalRisk;