import Question, { Answer } from '../../Question';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { SOCIAL_MEDIA } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { fetchSocialMedia } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function LinkedInURL() {
  const [linkedInUrl, setLinkedInUrl] = useRecoilState(fetchSocialMedia('linkedin'));
  const savingState = useAutoSave(SOCIAL_MEDIA, 'linkedIn', linkedInUrl);
  const handleChange = useCallback(e => setLinkedInUrl(e.target.value), [setLinkedInUrl]);

  if (typeof linkedInUrl === 'undefined') return null;

  return (
    <Question>
      What is the address of your company’s LinkedIn account? (optional)
      <Answer>
        <TextField
          placeholder={'https://linkedin.com'}
          icon={<LinkedInIcon />}
          value={linkedInUrl}
          onChange={handleChange}
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}

export default LinkedInURL;
