import { inputClasses } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

const styles = {
  CircularLoadingContainer: () => ({
    position: 'absolute', 
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100px'
  }),
  EndMessage: () => ({
    textAlign: 'center',
    marginTop: '15px'
  }),
  Paper: () => ({
    boxShadow: 'none',
    overflow: 'hidden',
  }),
  TableWrapper: () => ({
    overflowX: 'auto',
  }),
  Table: () => ({
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '10px',
    marginLeft: '-10px',
    marginRight: '-10px',
  }),
  TableRow: () => ({
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }),
  TableCell: ({ theme }) => ({
    color: theme.palette.text.main,
    backgroundColor: theme.palette.background.tableCell,
    borderBottom: 0,
    padding: '20px',
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.background.tableHeader,
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
        lineHeight: '22px',
      }
    },
    [`&.${tableCellClasses.body}, .${inputClasses.input}`]: {
      fontWeight: 300,
      fontSize: '13px',
      lineHeight: '22px',
      [theme.breakpoints.up('md')]: {
        fontSize: '15px',
        lineHeight: '30px',
      }
    },
  }),
  EditField: () => ({
    '& :focus': {
      fontWeight: 600
    }
  }),
  ActionCell: () => ({
    borderBottom: 0,
    cursor: 'pointer',
    padding: 0,
    textAlign: 'center',
    width: '42px',
  }),
  Container: ({ breakpoints }) => ({
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: '10px',
    padding: '0 10px',
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
    [breakpoints.down('lg')]: {
      paddingLeft: 0,
    }
  }),
  AddButton: ({ palette, breakpoints }) => ({
    padding: '0px',
    height: '60px',
    backgroundColor: palette.background.tableHeader,
    color: palette.primary.dark,
    border: `1px solid ${palette.primary.light}`,
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: palette.background.tableHeader,
    },
    [breakpoints.up('sm')]: {
      padding: '8px 8px 8px 8px',
      width: '274px'
    },
    [breakpoints.up('lg')]: {
      margin: '0 auto 10px auto',
      width: '274px',
    },
  }),
  AddIcon:  () => ({
    marginRight: '8px',
  }),
  Fab: () => ({
    boxShadow: 'none',
    height: '36px',
    width: '36px',
  }),
  FabDelete: () => ({
    backgroundColor: 'rgba(150, 32, 32, 0.2)',
    '&:hover': {
      backgroundColor: 'rgba(150, 32, 32, 0.1)',
    },
  }),
  FabEdit: () => ({
    backgroundColor: 'rgba(241, 181, 3, 0.2)',
    '&:hover': {
      backgroundColor: 'rgba(241, 181, 3, 0.1)',
    },
  }),
};

export default styles;
