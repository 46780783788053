import { Box, Typography } from '@mui/material';

import PrivateOfferTerms from './PrivateOfferTerms';
import SecurityClasses from './SecurityClasses';
import SecurityDifferences from './SecurityDifferences';
import SecurityTypes from './SecurityTypes';
import { fetchSecurities } from '../../../../_state/application-form';
import styles from '../styles';
import { useRecoilValue } from 'recoil';

function EquitySecurities() {
  const equitySecurities = useRecoilValue(fetchSecurities('equitySecurities'));
  
  return (
    <>
      <Box>
        <Typography variant='h2' sx={styles.Subtitle}>Equity Securities</Typography>
      </Box>
      <SecurityClasses />
      {equitySecurities && (
        <SecurityTypes />
      )}
      <PrivateOfferTerms />
      <SecurityDifferences />
    </>
  );
}

export default EquitySecurities;