import { Cell, Header } from '../../../../netcapital-components/Table';

import { HELP } from '../../../../static-info';
import Help from '../../../../netcapital-components/Help';

export default function EquitySecuritiesTableHeader(){
  return (
    <Header>
      <Cell>Class of Security</Cell>
      <Cell>
        <Help explanation={HELP['Amount Authorized']}>Amount Authorized</Help>
      </Cell>
      <Cell>
        <Help explanation={HELP['Amount Outstanding']}>Amount Outstanding</Help>
      </Cell>
      <Cell>
        <Help explanation={HELP['Voting Rights']}>Voting Rights</Help>
      </Cell>
      <Cell>
        <Help explanation={HELP['Securities Other Terms']}>Other Terms</Help>
      </Cell>
      <Cell>
        <Help explanation={HELP['Priority']}>Priority</Help>
      </Cell>
    </Header>
  );
}