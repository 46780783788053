const styles = {
  TextField: ({ breakpoints }) => ({
    '& .MuiInputBase-formControl': {
      borderRadius: '6px 6px 6px 6px',
    },
    '& .MuiOutlinedInput-input' : {
      [breakpoints.only('xs')]: {
        padding: '8px 8px',
      },
    }
  }),
  LoadingSection: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    padding: '48px 24px 24px',
  },
  Header: {
    display: 'inline-flex',
    position: 'relative',
    height: '50px',
    alignItems: 'center',
  },
  Title: ({ breakpoints }) => ({
    marginLeft: '25px',

    [breakpoints.up('xs')]: {
      fontSize: '28px',
    },
    [breakpoints.up('lg')]: {
      fontSize: '60px',
    }
  }),
  DeleteButton: ({ palette }) => ({
    backgroundColor: palette.error.light,
    '&:hover': {
      backgroundColor: palette.error.light,
    },
    position: 'absolute',
    right: '0',
  }),
  GoBackButton: ({ palette }) => ({
    backgroundColor: palette.secondary.light,
    '&:hover': {
      backgroundColor: palette.secondary.light,
    },
  }),
  UserBox: ({ palette }) => ({
    display: 'flex', 
    flexDirection: 'column',
    padding: '10px',
    '&:hover': {
      backgroundColor: palette.grey[100],
      cursor: 'pointer',
    },
  }),
  UserEmail: {
    fontWeight: '600'
  },
  SuccessMergeMessageContainer: ({ palette }) => ({
    marginTop: '5px',
    borderRadius: '4px',
    border: `1px solid ${palette.primary.main}`,
    padding: '15px'
  })
};

export default styles;
