import { Box, Typography } from '@mui/material';

import SecurityClasses from './SecurityClasses';
import styles from '../styles';

function WarrantsOptionsOtherRights(){
  return (
    <>
      <Box>
        <Typography variant='h2' sx={styles.Subtitle}>Warrants, Options and Other Rights </Typography>
      </Box>
      <SecurityClasses />
    </> 
  );
}

export default WarrantsOptionsOtherRights;