import { Box, Link, Typography } from '@mui/material';
import { HELP, RADIO_OPTIONS } from '../../../static-info';
import Question, { Answer } from '../../Question';
import { useCallback, useState } from 'react';

import Help from '../../../netcapital-components/Help';
import { KEY_PEOPLE } from '../../../static-info/listing-application-forms';
import RadioGroup from '../../../netcapital-components/RadioGroup';
import RelatedPartyTransactionsDialog from './RelatedPartiesTransactions/dialog';
import { fetchKeyPeople } from '../../../_state/application-form';
import styles from './styles';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useRecoilState } from 'recoil';

function KeyPeopleHasRelatedPartiesTransactions() {
  const [transactions, setHasRelatedPartiesTransactions] = useRecoilState(fetchKeyPeople('transactions'));
  const savingState = useAutoSave(KEY_PEOPLE, 'hasRelatedPartiesTransactions', transactions.hasRelatedPartiesTransactions);
  
  const [openDialog, setOpenDialog] = useState(false);
  const toggleDialog = useCallback(() => setOpenDialog(!openDialog), [openDialog, setOpenDialog]);

  const handleChangeHasRelatedPartiesTransactions = useCallback((e) => {
    setHasRelatedPartiesTransactions({...transactions, hasRelatedPartiesTransactions: e.target.value === 'true'});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);

  return (
    <Question>
      <Box>
        <Typography variant='body1' sx={[styles.BlockSpan, styles.BlockSpan.Bold]}>Before you answer this question, please make sure you understand <Link onClick={toggleDialog} sx={styles.Hyperlink}>related party transactions</Link>.</Typography>
        <RelatedPartyTransactionsDialog handleClose={toggleDialog} open={openDialog}/>
      </Box>
      <Typography variant='body1' sx={[styles.BlockSpan, styles.BlockSpan.Bold]}>Was or is the issuer, or any entities controlled by or under common control with the issuer, a party to any <Help explanation={HELP['Transaction']}>transaction</Help> since the beginning of the issuer’s last fiscal year, or any currently proposed transaction, where the amount involved exceeds five percent of the aggregate amount of capital raised by the issuer in reliance on Section 4(a)(6) of the Securities Act during the preceding 12-month period, including the amount the issuer seeks to raise in the current offering, in which any of the following persons had or is to have a direct or indirect material interest:</Typography>
      <ul>
        <li>any director or <Help explanation={HELP['Officer']}>officer</Help> of the issuer;</li>
        <li>any person who is, as of the most recent practicable date, the <Help explanation={HELP['Beneficial Ownership Key People']}>beneficial owner</Help> of 20 percent or more of the issuer’s outstanding voting equity securities, calculated on the basis of <Help explanation={HELP['Voting Power']}>voting power</Help>;</li>
        <li>if the issuer was incorporated or organized within the past three years, any <Help explanation={HELP['Promoter']}>promoter</Help> of the issuer; or</li>
        <li>any immediate <Help explanation={HELP['Family Member']}>family member</Help> of any of the foregoing persons.</li>
      </ul>
      <Answer>
        <RadioGroup
          name='keyPeopleHasRelatedPartiesTransactions'
          value={transactions.hasRelatedPartiesTransactions}
          onChange={handleChangeHasRelatedPartiesTransactions}
          options={RADIO_OPTIONS.YES_NO}
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}

export default KeyPeopleHasRelatedPartiesTransactions;
