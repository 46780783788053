import Question, { Answer } from '../../Question';

import { COMPANY_FORMATION } from '../../../static-info/listing-application-forms';
import RadioGroup from '../../../netcapital-components/RadioGroup';
import { fetchCompanyFormation } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function BusinessStructure({ doingBusinessAs }) {
  const [businessStructure, setBusinessStructure] = useRecoilState(fetchCompanyFormation('businessStructure'));
  const savingState = useAutoSave(COMPANY_FORMATION, 'businessStructure', businessStructure);
  const handleBusinessStructure = useCallback(e => setBusinessStructure(e.target.value), [setBusinessStructure]);
  
  return (
    <Question>
      What is the business structure of {doingBusinessAs || 'your company'}?
      <Answer>
        <RadioGroup
          name='businessStructure'
          value={businessStructure}
          onChange={handleBusinessStructure}
          options={[{id: 1, value: 'LLC', label: 'LLC'}, {id: 2, value: 'CORPORATION', label: 'Corporation'}]}
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}