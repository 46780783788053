import Question, { Answer } from '../../Question';

import AddressInput from '../../../netcapital-components/AddressInput';
import { CONTACT_INFO } from '../../../static-info/listing-application-forms';
import _ from 'lodash';
import { fetchContactInfo } from '../../../_state/application-form';
import produce from 'immer';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export default function Address() {
  const [ address, setAddress ] = useRecoilState(fetchContactInfo('address'));
  const savingState = useAutoSave(CONTACT_INFO, 'address', address);
  
  const handleChangeAddress = useCallback((attribute, newValue) => {
    const updatedAddress = produce(address, (draft) => {
      _.set(draft, attribute, newValue);
    });
    setAddress(updatedAddress);
  }, [setAddress, address]);

  if (typeof address === 'undefined') return null;

  return (
    <Question>
      What is your company’s primary place of business?
      <Answer>
        <AddressInput
          value={address}
          onChange={handleChangeAddress}
          forceCountry='US'
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}