import Question, { Answer } from '../../../Question';

import RelatedPartiesTransactionsTable from './table';
import { fetchKeyPeople } from '../../../../_state/application-form';
import { useRecoilValue } from 'recoil';

function RelatedPartiesTransactions() {
  const hasRelatedPartiesTransactions = useRecoilValue(fetchKeyPeople('transactions.hasRelatedPartiesTransactions'));

  if (!hasRelatedPartiesTransactions) return null;

  return (
    <Question>
      What are the details of each such transaction?
      <Answer>
        <RelatedPartiesTransactionsTable />
      </Answer>
    </Question>
  );
}

export default RelatedPartiesTransactions;
