import { Backdrop, Box, Container, Fade, IconButton, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import DOMPurify from 'dompurify';
import MUIModal from '@mui/material/Modal';
import OutlinedButton from '../OutlinedButton';
import PropTypes from 'prop-types';
import React from 'react';
import SolidButton from '../SolidButton';
import { styled } from '@mui/material/styles';
import styles from './styles';

const ModalDescription = styled(Typography, {
  name: 'ModalDescription',
})(styles.ModalDescription);

const ModalTitle = styled(Typography, {
  name: 'ModalTitle',
})(styles.ModalTitle);

const modal = (props) => {
  const { 
    color, 
    description,
    descriptionNode,
    descriptionColor,
    handlePrimaryAction, 
    handleSecondaryAction, 
    primaryBtnColor,
    primaryBtnTitle,
    onClose,
    open,
    secondaryBtnColor,
    secondaryBtnTitle,
    title
  } = props;
  return (
    <MUIModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      disableEscapeKeyDown
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: { backdropFilter: 'blur(2px)' }
      }}
    >
      <Fade in={open}>
        <Box sx={styles.ModalBox}>
          <IconButton sx={styles.IconButton} onClick={onClose}>
            <CloseIcon sx={styles.CloseIcon}/>
          </IconButton>
          <ModalTitle id="modal-title" variant="h3" color={color} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} />
          <ModalDescription id="modal-description" variant="body1" color={descriptionColor || color} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
          {descriptionNode}
          <Container sx={styles.Container}>
            {secondaryBtnTitle && <OutlinedButton color={secondaryBtnColor} sx={styles.OutlinedButton} onClick={handleSecondaryAction}>{secondaryBtnTitle}</OutlinedButton>}
            <SolidButton color={primaryBtnColor} sx={styles.SolidButton} onClick={handlePrimaryAction}>{primaryBtnTitle}</SolidButton>
          </Container>
        </Box>
      </Fade>
    </MUIModal>
  );
};

export const Modal = React.memo(modal);

//Modal 
Modal.defaultProps = {
  open: false,
  onClose: () => { return; },
  title: '',
  color: 'success',
  description: '',
  descriptionNode: '',
  descriptionColor: 'common',
  primaryBtnTitle: '',
  secondaryBtnTitle: '',
  primaryBtnColor: 'primary',
  secondaryBtnColor: 'secondary',
  handlePrimaryAction: () => { return; },
  handleSecondaryAction: () => { return; }
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['primary','secondary','success','error','info','warning','grey']),
  description: PropTypes.string,
  descriptionColor: PropTypes.oneOf(['success', 'error', 'warning', 'common', 'grey']),
  descriptionNode: PropTypes.node,
  primaryBtnColor: PropTypes.string.isRequired,
  secondaryBtnColor: PropTypes.string,
  primaryBtnTitle: PropTypes.node.isRequired,
  secondaryBtnTitle: PropTypes.node,
  handlePrimaryAction: PropTypes.func.isRequired,
  handleSecondaryAction: PropTypes.func
};

export default Modal;