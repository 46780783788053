const palette = {
  'mode': 'light',
  primary: {
    main: '#6C4EA5',
    dark: '#2A095A',
    light: '#E2DCED',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: 'rgb(241, 181, 3, 1)',
    light: 'rgb(241, 181, 3, 0.2)',
  },
  error: {
    main: '#962020',
    light: '#96202033'
  },
  common: {
    black: '#242424',
    white: '#FFFFFF'
  },
  warning: {
    main: '#ed6c02',
    light: '#FF9800',
    dark: '#E65100',
  },
  'info': {
    'main': '#0288d1',
  },
  success: {
    main: '#2E7D32',
  },
  grey: {
    '50': '#fafafa',
    '100': '#f5f5f5',
    '200': '#eeeeee',
    '300': '#DDE5E9',
    '400': '#bdbdbd',
    450: '#6B75888F',
    '500': '#9e9e9e',
    'main': '#757575',
    '600': '#757575',
    '700': '#616161',
    '800': '#424242',
    '900': '#4B4B4B',
  },
  shadows: {
    primary: 'rgba(0, 16, 101, 0.27)'
  },
  text: {
    primary: '#2A095A',
    title: '#24094B',
    white: '#fff',
    black: '#242424',
    'secondary': 'rgba(0, 0, 0, 0.6)',
    'disabled': 'rgba(0, 0, 0, 0.38)'
  },
  'divider': 'rgba(0, 0, 0, 0.12)',
  background: {
    active: 'rgba(108, 78, 165, 0.15)',
    default: '#fff',
    'paper': '#fff',
    tableCell: 'rgba(108, 78, 165, 0.03)',
    tableHeader: 'rgba(108, 78, 165, 0.1)'
  },
};

export default palette;
