import Question, { Answer } from '../../Question';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { METADATA } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { fetchMetadata } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function Description() {
  const [description, setDescription] = useRecoilState(fetchMetadata('description'));
  const savingState = useAutoSave(METADATA, 'description', description);
  const handleChangeDescription = useCallback(e => setDescription(e.target.value), [setDescription]);
  
  return (
    <Question>
      Description at /companies
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='bottom'
      >
        <TextField
          multiline
          rows={10}
          value={description || ''}
          onChange={handleChangeDescription}
          ownerState={{ subVariant: 'admin' }}
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}

export default Description;
