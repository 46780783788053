const styles = {
  Adverb: ({ palette }) => ({
    '&.MuiTypography-root': {
      color: palette.primary.dark,
      marginTop: '25px',
    },
  }),
  Link: ({ palette }) => ({
    color: palette.primary.dark, 
  }),
  Paragraph: ({ palette }) => ({
    color: palette.primary.dark,
  }),
};

export default styles;