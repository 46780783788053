import { keyPeopleState, keyPersonState } from '../../../../_state/application-form';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AutoComplete from './AutoComplete';
import { Box } from '@mui/material';
import Loading from '../../../Loading';
import Modal from '../../../../netcapital-components/Modal';
import Options from './Options';
import PropTypes from 'prop-types';
import { postMergeKeyPersonAndEntity } from '../../../../_actions/application-form/key-person.actions';
import styles from '../styles';
import { useAlertActions } from '../../../../_actions/alert.actions';
import { useResetRecoilState } from 'recoil';

export default function SearchUser({ firstName, lastName }){
  const { key: personKey, listingKey, offerKey } = useParams();
  const [loadingMergeRequest, setLoadingMergeRequest] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const alertActions = useAlertActions();
  const navigate = useNavigate();
  const resetKeyPerson = useResetRecoilState(keyPersonState);
  const resetKeyPeople = useResetRecoilState(keyPeopleState);

  const [selectedUser, setSelectedUser] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const toggleConfirmationModal = useCallback(() => setModalOpen(!modalOpen), [modalOpen, setModalOpen]);

  const handleSelectUser = useCallback((user) => {
    setSelectedUser(user);
    toggleConfirmationModal();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const handleMergeKeyPerson = useCallback(async () => {
    setLoadingMergeRequest(true); 
    const res = await postMergeKeyPersonAndEntity(listingKey, offerKey, personKey, selectedUser.userEntityKey);
    
    if(res.response && res.response.status > 204){
      setLoadingMergeRequest(false);
      setShowSuccess(false);
      toggleConfirmationModal();
      alertActions.error('An error occured saving the key-person form', res.response, res.message, res.request);
      return;
    }
    
    setLoadingMergeRequest(false);
    toggleConfirmationModal();
    setShowSuccess(true);  
    resetKeyPerson();
    resetKeyPeople();
    navigate(`/listings/${listingKey}/offers/${offerKey}/key-people`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertActions, offerKey, personKey, selectedUser.userEntityKey]);

  return (
    <Box>
      <Loading loading={loadingMergeRequest} />
      <Modal
        open={modalOpen}
        onClose={toggleConfirmationModal}
        color='warning'
        descriptionColor='common'
        title='Please, confirm'
        description={`You are about to merge ${firstName || 'New'} ${lastName || 'Person'} with the user: <br /><b>${selectedUser.name} - <u>${selectedUser.email}</u></b>. <br /><br /> This action can not be undone!`} 
        primaryBtnTitle='Yes, Merge'
        primaryBtnColor='secondary'
        handlePrimaryAction={handleMergeKeyPerson}
        secondaryBtnTitle='Cancel'
        secondaryBtnColor='grey'
        handleSecondaryAction={toggleConfirmationModal} 
      />
      <AutoComplete handleSelectUser={handleSelectUser} ownerState={{ subVariant: 'admin' }} />
      {showSuccess && (
        <Box sx={styles.SuccessMergeMessageContainer}>
          <Options user={selectedUser} />
        </Box>
      )}
    </Box>
  );
}

SearchUser.defaultProps = {
  firstName: '',
  lastName: ''
};

SearchUser.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string
};