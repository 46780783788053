import FileField, { DEFAULT_FILE_TYPES } from '../FileField';
import Question, { Answer } from '../../Question';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { fetchCapTableFile, fetchCompanyFormation } from '../../../_state/application-form';
import { useGetCapTable, useGetCompanyFormation } from '../../../_actions/application-form';

import Form from '../FormWrapper';
import { HELP } from '../../../static-info';
import Help from '../../../netcapital-components/Help';
import Loading from '../../Loading';
import PrincipalOwners from './principalOwnersTable';
import { Typography } from '@mui/material';
import { isAdminState } from '../../../_state/user';
import styles from './styles';
import { useRecoilValue } from 'recoil';

const CAP_TABLE_FILE_TYPES = DEFAULT_FILE_TYPES.concat([
  '.csv',
  '.xls',
  '.xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
]);

function CapTable() {
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetCapTable(listingKey, offerKey);
  const { loaded: loadedCompanyFormation } = useGetCompanyFormation(listingKey, offerKey);
  const businessStructure = useRecoilValue(fetchCompanyFormation('businessStructure'));
  const isAdmin = useRecoilValue(isAdminState);

  return (
    <Form title='Cap Table' loaded={loaded}>
      <Loading loading={!loaded || !loadedCompanyFormation} />
      <Typography variant='body1'>A capitalization table, also known as (a “cap table”), is a spreadsheet or table that shows the equity capitalization for your company. Securities include stock, convertible notes, warrants, stock options and equity grants. Please upload your company’s cap table. This should include both securities authorized but not yet issued and those already issued to shareholders. At a minimum, the table should include for each shareholder: their full name, the number of shares (vested and unvested), the class of security.</Typography>
      <Question>
        Upload your Cap Table
        <Answer>
          <FileField usage='CAP_TABLE' state={fetchCapTableFile} fileType={CAP_TABLE_FILE_TYPES.join(',')} />
        </Answer>
      </Question>
      {isAdmin && (
        <PrincipalOwners />
      )}
      {businessStructure === 'LLC' && (
        <Question>
          Upload your <Help explanation={HELP['What is an Operating Agreement?']}>Operating Agreement</Help>
          <Answer>
            <FileField usage='OPERATING_AGREEMENT' state={fetchCapTableFile} />
          </Answer>
        </Question>
      )}
      {businessStructure === 'CORPORATION' && (
        <Question>
          Upload your <Help explanation={HELP['What are Corporate Bylaws?']}>Corporate Bylaws</Help>
          <Answer>
            <FileField usage='CORPORATE_BYLAWS' state={fetchCapTableFile} />
          </Answer>
        </Question>
      )}
      {!businessStructure && (
        <Typography variant='body1' sx={styles.NoBusinessStructure}>To determine which formation documents we need, <RouterLink to='../company-formation'>please select your company’s legal structure.</RouterLink></Typography>
      )}
    </Form>
  );
}

export default CapTable;
