import Question, { Answer } from '../../Question';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import MoneyInput from '../../../netcapital-components/MoneyInput';
import { SHARES } from '../../../static-info/listing-application-forms';
import { fetchShares } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export default function MinimumInvestmentAmount({ readOnly }){
  const [minInvestmentLimit, setMinInvestmentLimit] = useRecoilState(fetchShares('minInvestmentLimit'));
  const savingState = useAutoSave(SHARES, 'minInvestmentLimit', minInvestmentLimit);
  const onChangeMinInvestmentLimit = useCallback((value) => {
    setMinInvestmentLimit(value);
  },[setMinInvestmentLimit]);
  
  return (
    <Question>
      Minimum Investment Amount
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='bottom'
      >
        <MoneyInput
          value={minInvestmentLimit}
          disabled={readOnly}
          onChange={onChangeMinInvestmentLimit}
          error={savingState && savingState.state === 'error'}
          isNoBorder={false}
          isBoldFocus={false}
          ownerState={{ subVariant: 'admin' }}
        />
      </Answer>
    </Question>
  );
}