import { RISKS } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { fetchRisks } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function SecurityCaveats() {
  const [securityCaveats, setSecurityCaveats] = useRecoilState(fetchRisks('securityCaveats'));
  const savingState = useAutoSave(RISKS, 'securityCaveats', securityCaveats);
  const handleChange = useCallback(e => setSecurityCaveats(e.target.value), [setSecurityCaveats]);

  return (
    <TextField
      multiline
      rows={10}
      value={securityCaveats}
      onChange={handleChange}
      placeholder="Effects of rights"
      error={savingState && savingState.state === 'error'}
    />
  );
}

export default SecurityCaveats;
