import Question, { Answer } from '../../Question';
import { useGetContactInfo, useGetFundingHistory } from '../../../_actions/application-form';

import { CreditorsTable } from './creditorsTable';
import CrowdfundingHistory from './CrowdfundingHistory';
import Form from '../FormWrapper';
import HasLifetimeCrowdfundingHistory from './HasLifetimeCrowdfundingHistory';
import OtherCrowdfundingHistory from './OtherCrowdfundingHistory';
import { useParams } from 'react-router-dom';

function FundingHistory() {
  const {listingKey, offerKey} = useParams();
  const { loaded } = useGetFundingHistory(listingKey, offerKey);
  const { loaded: loadedContactInfo } = useGetContactInfo(listingKey, offerKey);

  return (
    <Form title='Funding History' loaded={loaded && loadedContactInfo}>
      <HasLifetimeCrowdfundingHistory />
      <OtherCrowdfundingHistory />
      <CrowdfundingHistory />
      <Question>
        List your company&apos;s outstanding loans, promissory notes, and convertible debt instruments. Include the dollar amount, interest rate, maturity date and any other terms.
        <Answer>
          <CreditorsTable />
        </Answer>
      </Question>
    </Form>
  );
}

export default FundingHistory;
