import palette from './palette';

const typography = {
  'htmlFontSize': 16,
  fontFamily: '"Inter"',
  'fontSize': 14,
  'fontWeightLight': 300,
  'fontWeightRegular': 400,
  'fontWeightMedium': 500,
  'fontWeightBold': 700,
  h1: {
    fontFamily: 'Halant',
    fontWeight: 700,
    fontSize: '24px',
    color: palette.text.primary,
    lineHeight: 0.85,
    'letterSpacing': '-0.01562em',
  },
  h2: {
    fontFamily: 'Halant',
    fontWeight: 700,
    fontSize: '20px',
    color: palette.text.primary,
    'lineHeight': 1.2,
    'letterSpacing': '-0.00833em',
  },
  h3: {
    fontFamily: 'Halant',
    fontWeight: 700,
    fontSize: '18px',
    color: palette.text.primary,
    lineHeight: 1.167,
    letterSpacing: '0em',
  },
  'subtitle1': {
    'fontWeight': 400,
    'fontSize': '1rem',
    'lineHeight': 1.75,
    'letterSpacing': '0.00938em'
  },
  'subtitle2': {
    'fontWeight': 600,
    'fontSize': '1rem',
    'lineHeight': 1.75,
    'letterSpacing': '0.00938em'
  },
  body1: {
    fontWeight: 300,
    fontSize: '15px',
    'lineHeight': 1.5,
    'letterSpacing': '0.00938em'
  },
  button: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    'lineHeight': 0,
    'letterSpacing': '0.02857em',
    'textTransform': 'uppercase',
  },
  'caption': {
    color: palette.grey[600],
    'fontWeight': 400,
    'fontSize': '0.75rem',
    'lineHeight': 1.66,
    'letterSpacing': '0.03333em'
  },
  'note': {
    fontStyle: 'italic',
    fontSize: '1rem',
  }
};

export default typography;
