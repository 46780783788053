import { Cell, Header } from '../../../../netcapital-components/Table';

function EquivalentLineItemsTableHeader(){
  return (
    <Header>
      <Cell>Total Income</Cell>
      <Cell>Taxable Income</Cell>
      <Cell>Taxes Paid</Cell>
    </Header>
  );
}

export default EquivalentLineItemsTableHeader;