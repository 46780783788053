const styles = {
  DialogOption: () => ({
    display: 'flex',
    fontSize: '14px',
    fontWeight: 600,
    cursor: 'pointer',
  }),
  DialogEditOption: ({ palette }) => ({
    color: palette.primary.light,
    textDecorationColor: palette.primary.light,
  }),
  DialogSaveOption: ({ palette }) => ({
    color: palette.success.main,
    textDecorationColor: palette.success.main,
  }),
  DialogIcon: () => ({
    width: '14px',
    marginRight: '5px'
  }),
  DialogContent: () => ({
    width: '100%',
    padding: '40px 50px'
  }),
  DialogIconButton: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  DialogCloseIcon: {
    height: '20px',
    width: '20px'
  },
  EquityTableSizes:{
    AdditionalRights: {
      width: '100px'
    },
    Priority: {
      width: '90px'
    },
    SecurityAmount: {
      width: '110px'
    },
    SecurityAmountOutstanding: {
      width: '110px'
    },
    SecurityType: {
      width: '165px'
    },
    VotingRights: {
      width: '75px'
    }
  },
  HyperLink: ({ palette }) => ({
    color: palette.primary.dark,
    fontWeight: 600,
    cursor: 'pointer',
  }),
  OptionSecurityTableSizes: {
    OptionDescription: {
      width: '200px'
    },
    OptionNum: {
      width: '200px'
    },
    OptionType: {
      width: '200px'
    }
  },
  SecurityDifferencesTextarea: {
    marginTop: '15px'
  },
  Subtitle: ({breakpoints}) => ({
    fontSize: '20px',
    [breakpoints.up('lg')]: {
      fontSize: '40px',
    },
  }),
  TablePreface: {
    display: 'block',
    marginTop: '15px'
  },
};

export default styles;