import Question, { Answer } from '../../../Question';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Typography } from '@mui/material';
import WarrantsOptionsOtherRightsTable from '../WarrantsOptionsOtherRightsTable';
import styles from '../styles';

function SecurityClasses(){
  return (
    <Question>
      List any securities reserved for issuance upon exercise or conversion.
      <Typography variant='body1' sx={styles.TablePreface}>You might also list all convertible debts and other hybrid securities.</Typography>
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='top'
      >
        <WarrantsOptionsOtherRightsTable />
      </Answer>
    </Question>
  );
}

export default SecurityClasses;