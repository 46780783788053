import { Cell, Header } from '../../../../netcapital-components/Table';

function FiscalYearsTableHeader(){
  return (
    <Header>
      <Cell>Fiscal Year End Date</Cell>
    </Header>
  );
}

export default FiscalYearsTableHeader;