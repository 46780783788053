import Question, { Answer } from '../../Question';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FileField from '../FileField';
import { fetchFinancialStatementsFile } from '../../../_state/application-form';

function AnnualReport(){
  return (
    <Question>
      Upload your Most Recent Annual Report
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='bottom'
      >
        <FileField usage='ANNUAL_REPORT_LATEST' state={fetchFinancialStatementsFile} />
      </Answer>
    </Question>
  );
}

export default AnnualReport;