import { Box, IconButton, Link, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import Button from '../SolidButton';
import { CircularLoading } from '../CircularLoading';
import CloseIcon from '@mui/icons-material/Close';
import DropHereIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import styles from './styles';

const Input = styled('input')(styles.Input);

const preventDefaultWindow = () => {
  window.addEventListener('dragover', e => e.preventDefault(), false);
  window.addEventListener('drop', e => e.preventDefault(), false);
};

function FileUpload(props) {
  const [dragActive, setDragActive] = useState(false);
  const { file, fileType, onChange, usage, loading } = props;

  preventDefaultWindow();

  const changeHandler = useCallback((e) => {
    if (!e || !e.target || !e.target.value){
      return;
    }

    const isFile = e && e.target && e.target.files && e.target.files.length > 0;
    onChange(isFile ? e.target.files[0] : null);
    e.preventDefault();
    e.target.value = null;
  },[onChange]);

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
      return;
    }

    setDragActive(false);
  },[setDragActive]);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onChange(e.dataTransfer.files[0]);
      return;
    }
  },[onChange, setDragActive]);

  const dropHereComponent = () => {
    return (
      <Box sx={styles.FileLoaded}>
        <Box sx={styles.DroppingFile}>
          <DropHereIcon sx={styles.Icon.Drop} />
          <Typography sx={styles.DropTypography} variant='button'>Drop your file here</Typography>
        </Box>
      </Box>
    );
  };

  const fileLoadedComponent = () => {
    return (
      <Link href={file.url} target='_blank' rel='noopener noreferrer' sx={styles.FileLoadedLink}>
        <Box sx={styles.FileLoaded}>
          <Box sx={styles.Box}>
            <Typography sx={styles.ButtonTypography} variant='button' title={file.name}>
              File uploaded: {file.name.length >= 22 ? `${file.name.slice(0, Math.round(file.name.length/3))}...` : file.name}
            </Typography>
          </Box>
          <IconButton color='error' sx={styles.IconButton} onClick={changeHandler} value={'delete'}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Link>
    );
  };
  
  const fileLoadingComponent = () => {
    return (
      <Box>
        <CircularLoading />
      </Box>
    );
  };

  const isFilePicked = file && file.name;

  return (
    <Box onDrop={handleDrop} onDragEnter={handleDrag} onDragLeave={handleDrag} sx={styles.UploadBox}>
      <Input
        accept={fileType}
        id={`file_${usage}`}
        multiple={false}
        onChange={changeHandler}
        type='file'
      />
      {dragActive && dropHereComponent()}
      {loading && fileLoadingComponent()}
      {isFilePicked && !loading && fileLoadedComponent()}
      {!isFilePicked && !loading && !dragActive && (
        <label htmlFor={`file_${usage}`}> 
          <Button component='span' sx={styles.PseudoButton}>
            <FileUploadIcon sx={styles.Icon.Upload} />
            <Typography variant='button' sx={styles.UploadButtonTypography}>Upload your file here</Typography>
          </Button>
        </label>
      )}
    </Box>
  );
}

FileUpload.defaultProps = {
  file: {
    name: '',
    url: '',
    usage: '',
  }
};

FileUpload.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    usage: PropTypes.string,
  }),
  fileType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FileUpload;