import { Cell, Footer } from '../../../../netcapital-components/Table';

import { NumberFormatCents } from '../../../../netcapital-components/NumberFormat';
import PropTypes from 'prop-types';

export default function UseOfProceedsTableFooter({ amountMinTotal, amount4a6MaxTotal, amountMaxTotal, isSideBySideOffering }){
  const amount4a6MaxTotalCellJsx = (
    <Cell>
      <NumberFormatCents value={amount4a6MaxTotal} displayType={'text'} />
    </Cell>
  );

  return (  
    <Footer>
      <Cell>Total</Cell>
      <Cell>
        <NumberFormatCents value={amountMinTotal} displayType={'text'} />
      </Cell>
      {isSideBySideOffering ? amount4a6MaxTotalCellJsx : null}
      <Cell>
        <NumberFormatCents value={amountMaxTotal} displayType={'text'} />
      </Cell>
    </Footer>
  );
}

UseOfProceedsTableFooter.defaultProps = {
  amountMinTotal: 0,
  amountMaxTotal: 0,
  amount4a6MaxTotal: 0,
  isSideBySideOffering: false
};

UseOfProceedsTableFooter.propTypes = {
  amountMinTotal: PropTypes.number,
  amount4a6MaxTotal: PropTypes.number,
  amountMaxTotal: PropTypes.number,
  isSideBySideOffering: PropTypes.bool
};