import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  ModeStandbyOutlined as IconBullet,
  ExpandMore as IconExpandMore,
  PeopleAltOutlined as IconPeople
} from '@mui/icons-material';
import { KEY_PEOPLE, KEY_PERSON } from '../../../static-info/listing-application-forms';
import { useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { assembleAccordionMenuTopLevelItemSx } from './utils';
import styles from './styles';

function KeyPeopleMenu({ formId, keyPeople, personKey, savingForm, savingStatus }) {
  const [keyPeopleAccordionExpanded, setKeyPeopleAccordionExpanded] = useState(false);
  const handleAccordionToggle = useCallback(() => {
    setKeyPeopleAccordionExpanded(!keyPeopleAccordionExpanded);
  },[keyPeopleAccordionExpanded]);

  const AccordionMenuTopLevelItemSx = assembleAccordionMenuTopLevelItemSx(formId);

  return (
    <Accordion
      expanded={keyPeopleAccordionExpanded}
      elevation={0}
      sx={[styles.AccordionMenu, formId === 'key-people' && styles.ActiveAccordion]}
    >
      <AccordionSummary
        expandIcon={<IconExpandMore onClick={handleAccordionToggle}/>}
        aria-controls='panel1bh-content'
        id='panel1bh-header'
      >
        <MenuItem 
          component={RouterLink}
          sx={AccordionMenuTopLevelItemSx}
          tabIndex={0} 
          to='key-people'
          disabled={savingForm !== KEY_PEOPLE && savingStatus === 'SAVING'}
        >
          <ListItemIcon>
            <IconPeople fontSize='small' />
          </ListItemIcon>
          <ListItemText>Key People</ListItemText>
        </MenuItem>
      </AccordionSummary>
      <AccordionDetails sx={styles.AccordionMenuDetails}>
        {keyPeople
          ?
          keyPeople.map((person) => (
            <MenuItem
              component={RouterLink}
              key={person.key}
              sx={[styles.MenuItem, personKey === person.key && styles.AccordionMenuItemActive]}
              tabIndex={0}
              to={`key-people/${person.key}`}
              disabled={savingForm !== KEY_PERSON && savingStatus === 'SAVING'}
            >
              <ListItemIcon >
                <IconBullet fontSize='xs' />
              </ListItemIcon>
              <ListItemText>{person.firstName} {person.lastName}</ListItemText>
            </MenuItem>
          ))
          :
          <Typography color='GrayText'>No key people added</Typography>
        }
      </AccordionDetails>
    </Accordion>
  );
}

KeyPeopleMenu.defaultProps = {
  formId: '',
  keyPeople: [],
  personKey: '',
  savingForm: '',
  savingStatus: '',
};

KeyPeopleMenu.propTypes = {
  formId: PropTypes.string,
  keyPeople: PropTypes.arrayOf(PropTypes.shape),
  personKey: PropTypes.string,
  savingForm: PropTypes.string,
  savingStatus: PropTypes.string,
};

export default KeyPeopleMenu;
