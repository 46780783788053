import Question, { Answer } from '../../Question';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FileField from '../FileField';
import { fetchFinancialStatementsFile } from '../../../_state/application-form';

function AdditionalInformation(){
  return (
    <Question>
      Upload Additional Information
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='bottom'
      >
        <FileField usage='OTHER_FINANCIAL' state={fetchFinancialStatementsFile} />
      </Answer>
    </Question>
  );
}

export default AdditionalInformation;