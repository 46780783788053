import Question, { Answer } from '../../Question';

import BusinessSummary from './BusinessSummary';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FileField from '../FileField';
import Form from '../FormWrapper';
import { fetchCompanyDescriptionFile } from '../../../_state/application-form';
import { isAdminState } from '../../../_state/user';
import { useGetCompanyDescription } from '../../../_actions/application-form';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

function CompanyDescription() {
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetCompanyDescription(listingKey, offerKey);
  const isAdmin = useRecoilValue(isAdminState);

  return (
    <Form title='Company Description' loaded={loaded}>
      <BusinessSummary />
      {isAdmin && (
        <Question>
          Upload your Business Plan
          <Answer
            helperColor='warning.main'
            helperContent='Admin Only'
            helperIcon={<ErrorOutlineOutlinedIcon />}
            helperPosition='bottom'
          >
            <FileField usage='BUSINESS_PLAN' state={fetchCompanyDescriptionFile} />
          </Answer>
        </Question>
      )}
      {isAdmin && (
        <Question>
          Upload your Pitch Deck
          <Answer
            helperColor='warning.main'
            helperContent='Admin Only'
            helperIcon={<ErrorOutlineOutlinedIcon />}
            helperPosition='bottom'
          >
            <FileField usage='PITCH_DECK' state={fetchCompanyDescriptionFile} />
          </Answer>
        </Question>
      )}
    </Form>
  );
}

export default CompanyDescription;
