const styles = {
  Backdrop: {
    backdropFilter: 'blur(2px)'
  },
  ModalBox: ({ palette, breakpoints} ) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: palette.common.white,
    borderRadius: '16px 16px 16px 16px',
    boxShadow: `0px 4px 20px ${palette.shadows.primary}`,
    padding: '24px 24px 24px 24px',
    '&:focus': {
      outline: 'none',
    },

    [breakpoints.up('xs')]: {
      width: '100%'
    },
    [breakpoints.up('md')]: {
      width: '75%'
    },
    [breakpoints.up('lg')]: {
      width: '50%'
    },
  }),
  Container: ({ breakpoints }) => ({
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: '16px',
    }
  }),
  IconButton: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  CloseIcon: {
    height: '20px',
    width: '20px'
  },
  ModalTitle: ({ theme }) => ({
    fontFamily: 'Inter',
    fontWeight: '600',
    textAlign: 'center',
    marginTop: '25px',
    [theme.breakpoints.up('xs')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '22px',
    },
  }),
  ModalDescription: {
    marginTop: '15px',
    textAlign: 'center',
    width: '100%'
  },
  SolidButton: ({ breakpoints }) => ({
    padding: '0px',
    marginTop: '16px',
    marginLeft: '8px',
    whiteSpace: 'nowrap',
    'span': {
      fontSize: '14px',
    },
    'svg': {
      position: 'relative',
      top: '3px',
      height: '18px',
      width: '18px'
    },
    [breakpoints.up('sm')]: {
      padding: '8px 16px'
    }
  }),
  OutlinedButton: ({ breakpoints }) => ({
    padding: '0px',
    marginTop: '16px',
    marginLeft: '8px',
    whiteSpace: 'nowrap',
    'span': {
      fontSize: '14px',
    },
    'svg': {
      position: 'relative',
      top: '3px',
      height: '18px',
      width: '18px'
    },
    [breakpoints.up('sm')]: {
      padding: '8px 16px'
    }
  })
};

export default styles;