const styles = {
  CertificateFormationBody: ({ breakpoints }) => ({
    width: '100%',
    [breakpoints.up('lg')]: {
      width: '85%',
    }
  }),
  CertificateFormationTitle: ({ breakpoints }) => ({
    fontWeight: 700,
    [breakpoints.up('xs')]: {
      fontSize: '24px',
    },
    [breakpoints.up('lg')]: {
      fontSize: '40px',
    },
  }),
};

export default styles;
