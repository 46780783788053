import { Cell, Header } from '../../../../netcapital-components/Table';

import { NumberFormatCents } from '../../../../netcapital-components/NumberFormat';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import styles from '../styles';

export default function UseOfProceedsTableHeader({ isSideBySideOffering, raiseAmountMax, raiseAmount4a6Max }){
  const raiseAmount4a6MaxCellJsx = (
    <Cell>Allocation at&nbsp;
      <NumberFormatCents value={raiseAmount4a6Max} displayType={'text'} />
      &nbsp;raised (in $)
    </Cell>
  );

  return (
    <Header>
      <Cell>Expense</Cell>
      <Cell>
        <Typography variant='body1' sx={isSideBySideOffering ? [styles.UseOfProceedsTableSizes.AmountMinHeader, styles.CellHeaderOverride] : styles.CellHeaderOverride}>Allocation at $10,000 raised (in $)</Typography>
      </Cell>
      {isSideBySideOffering ? raiseAmount4a6MaxCellJsx: null}
      <Cell>Allocation at&nbsp;
        <NumberFormatCents value={raiseAmountMax} displayType={'text'} />
        &nbsp;raised (in $)
      </Cell>
    </Header>
  );
}

UseOfProceedsTableHeader.defaultProps = {
  isSideBySideOffering: false,
  raiseAmountMax: 0,
  raiseAmount4a6Max: PropTypes.number
};

UseOfProceedsTableHeader.propTypes = {
  isSideBySideOffering: PropTypes.bool,
  raiseAmountMax: PropTypes.number,
  raiseAmount4a6Max: PropTypes.number
};
