import { Link as RouterLink, useParams } from 'react-router-dom';
import Table, { Body } from '../../../../netcapital-components/Table';
import { fetchCompanyFormation, fetchFinancialStatements, fetchFormGlobals } from '../../../../_state/application-form';
import { useCreateFinancialReport, useGetCompanyFormation } from '../../../../_actions/application-form';

import { DateTime } from 'luxon';
import FiscalYearEndDate from './row';
import FiscalYearsTableHeader from './header';
import Loading from '../../../Loading';
import { Typography } from '@mui/material';
import styles from '../styles';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

const MAX_REPORTS = 2;

function FiscalYearsTable() {
  const { listingKey, offerKey } = useParams();
  useGetCompanyFormation(listingKey, offerKey);
  const financialReports = useRecoilValue(fetchFinancialStatements('financialReports'));
  const dateFormed = DateTime.fromFormat(useRecoilValue(fetchCompanyFormation('dateFormed')) || '', 'yyyy-MM-dd');
  const offerStartDate = DateTime.fromFormat(useRecoilValue(fetchFormGlobals('offerStartDate')) || '', 'yyyy-MM-dd');
  const numReports = Math.min(MAX_REPORTS, Math.floor(offerStartDate.diff(dateFormed, 'years').toObject().years));
  const { loading: isCreatingNewFinancialReport, create: createFinancialReport } = useCreateFinancialReport(listingKey, offerKey);
  
  const onCreateFinancialReport = useCallback(() => {
    const fiscalYear = offerStartDate.year - (financialReports.length + 1);
    return createFinancialReport({
      accountsReceivable: 0,
      assets: 0,
      cash: 0,
      costOfGoodsSold: 0,
      dateEnd: DateTime.utc(fiscalYear, 12, 31).toFormat('yyyy-MM-dd'),
      longTermDebt: 0,
      netIncome: 0,
      revenue: 0,
      shortTermDebt: 0,
      taxesPaid: 0,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFinancialReport, financialReports, numReports, offerStartDate.year]);
  
  if (!dateFormed.isValid) {
    return (
      <Typography variant='body1' sx={styles.Paragraph}>To determine your company’s fiscal year status, <RouterLink to='../company-formation'>please specify your company’s date of formation.</RouterLink></Typography>
    );
  }

  return (
    <Table disableAddRow={financialReports.length >= 2} onNewRow={onCreateFinancialReport} newRowCTA='Add Year'>
      <Loading loading={isCreatingNewFinancialReport} />
      <FiscalYearsTableHeader />
      <Body>
        {financialReports && financialReports.map((report, index) => (
          <FiscalYearEndDate report={report} index={index} key={report.key} />
        ))}
      </Body>
    </Table>
  );
}

export default FiscalYearsTable;
