import Question, { Answer } from '../../Question';
import { useRecoilState, useRecoilValue } from 'recoil';

import { CONTACT_INFO } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { fetchContactInfo } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';

export default function Website() {
  const [website, setWebsite] = useRecoilState(fetchContactInfo('website'));
  const savingState = useAutoSave(CONTACT_INFO, 'website', website);
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
    
  const handleChange = useCallback((e) => setWebsite(e.target.value), [setWebsite]);
  
  return (
    <Question>
      What is the address of {doingBusinessAs || 'your company'}’s website?
      <Answer>
        <TextField 
          value={website || ''}
          onChange={handleChange}
          name='website'
          placeholder='https://www.company.com'
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}