import Question, { Answer } from '../../Question';

import FileField from '../FileField';
import { fetchFinancialStatementsFile } from '../../../_state/application-form';

function ChangeInOwnersEquityStatement(){
  return (
    <Question>
      Upload your Change in Owners’ Equity Statement
      <Answer>
        <FileField usage='CHANGE_IN_EQUITY' state={fetchFinancialStatementsFile} />
      </Answer>
    </Question>
  );
}

export default ChangeInOwnersEquityStatement;