export const TOTAL_MIN_ALLOCATION = 10000 * 100; // 1,000,000 = USD 10,000.00
export const NETCAPITAL_FEE = 0.049; // 4.9%

export const calculateNetcapitalFee = (amount) => {
  // Rounded down to the nearest dollar
  return Math.floor(amount * NETCAPITAL_FEE / 100) * 100;
};

export const calculateAmountMinTotal = (proceeds) => {
  //(10000 * 4.9% NCPL fee) * 100
  let total = calculateNetcapitalFee(TOTAL_MIN_ALLOCATION);
  proceeds.forEach((proceed) => {
    if(proceed.item !== 'Unallocated Funds'){
      total += Number((proceed.amountMin));
    } 
  });
  return total;
};
      
export const calculateAmountMaxTotal = (proceeds, raiseAmountMax) => {
  //maxRaise * 4.9% NCPL fee
  let total = calculateNetcapitalFee(raiseAmountMax);
  proceeds.forEach(proceed => {
    if(proceed.item !== 'Unallocated Funds'){
      total += Number(proceed.amountMax);
    }
  });
  return total;
};

export const calculateAmount4a6MaxTotal = (proceeds, raiseAmount4a6Max) => {
  //max4a6Raise * 4.9% NCPL fee
  let total = calculateNetcapitalFee(raiseAmount4a6Max);
  proceeds.forEach(proceed => {
    if(proceed.item !== 'Unallocated Funds'){
      total += Number(proceed.amount4a6Max);
    }
  });
  return total;
};

export const calculateMinUnallocatedFunds = (proceeds) => {
  //(10000 - 490) = 9510 * 1000
  let totalMinUnallocated = 951000;
  proceeds.forEach(proceed => {
    if(proceed.item === 'Unallocated Funds'){
      totalMinUnallocated -= 0;
    } else {
      totalMinUnallocated -= (Number(proceed.amountMin));
    }
  });
  return Math.floor(totalMinUnallocated);
};

export const calculateMaxUnallocatedFunds = (proceeds, raiseAmountMax) => {
  //maxRaise - (maxRaise * 4.9% NCPL fee)
  let totalMaxUnallocated = raiseAmountMax - calculateNetcapitalFee(raiseAmountMax);
  proceeds.forEach(proceed => {
    if(proceed.item === 'Unallocated Funds'){
      totalMaxUnallocated -= 0;
    } else {
      totalMaxUnallocated -= (Number(proceed.amountMax));
    }
  });
  return Math.floor(totalMaxUnallocated);
};

export const calculate4a6MaxUnallocatedFunds = (proceeds, raiseAmount4a6Max) => {
  //max4a6Raise - (max4a6Raise * 4.9% NCPL fee)
  let total4a6MaxUnallocated = raiseAmount4a6Max - calculateNetcapitalFee(raiseAmount4a6Max);
  proceeds.forEach(proceed => {
    if(proceed.item === 'Unallocated Funds'){
      total4a6MaxUnallocated -= 0;
    } else {
      total4a6MaxUnallocated -= (Number(proceed.amount4a6Max));
    }
  });

  return Math.floor(total4a6MaxUnallocated);
};

export const validateNewProceedAmount = (proceeds, index, newAmount, column, maxAllocation) => {
  let proceedTotal = calculateNetcapitalFee(maxAllocation);
  
  proceeds.forEach((proceed, i) => {
    if(proceed.item !== 'Unallocated Funds' && i !== index && column === 'amountMin'){
      proceedTotal += (Number(proceed.amountMin));
    }
    if(proceed.item !== 'Unallocated Funds' && i !== index && column === 'amount4a6Max'){
      proceedTotal += (Number(proceed.amount4a6Max));
    }
    if(proceed.item !== 'Unallocated Funds' && i !== index && column === 'amountMax'){
      proceedTotal += (Number(proceed.amountMax));
    }
  });

  if(proceedTotal + newAmount > maxAllocation){
    return false;
  }
  return true;
};