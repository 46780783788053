import Table, { Body, Cell, EditField, Header, Row } from '../../../netcapital-components/Table';

import PropTypes from 'prop-types';
import _ from 'lodash';

function TrackingPixelConversionLabels({ dataLayerConfig, baseAttribute, onChange }) {
  return (
    <Table onNewRow={null}>
      <Header>
        <Cell>Event</Cell>
        <Cell>Conversion Label</Cell>
      </Header>
      <Body>
        <Row>
          <Cell>Add To Cart</Cell>
          <Cell>
            <EditField
              value={_.get(dataLayerConfig, `${baseAttribute}.addToCart`)}
              onChange={onChange(`${baseAttribute}.addToCart`)}
              placeholder='Pixel ID'
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Investment Commitment</Cell>
          <Cell>
            <EditField
              value={_.get(dataLayerConfig, `${baseAttribute}.purchase`)}
              onChange={onChange(`${baseAttribute}.purchase`)}
              placeholder='Pixel ID'
            />
          </Cell>
        </Row>
      </Body>
    </Table>
  );
}

TrackingPixelConversionLabels.defaultProps = {
  dataLayerConfig: {
    bingPixel: '',
    oribiPixel: '',
    redditPixel: '',
    tiktokPixel: '',
    gaTrackingId: '',
    hubioIdPixel: '',
    yahooPixelId: '',
    facebookPixel: '',
    linkedInPixel: '',
    snapchatPixel: '',
    yahooProjectId: '',
    twitterPurchasePixel: '',
    twitterUniversalPixel: '',
    linkedInConversionPixels: {
      purchase: '',
      addToCart: ''
    },
    googleAdsConversions: {
      id: '',
      labels: {
        purchase: '',
        addToCart: ''
      }
    }
  }
};

TrackingPixelConversionLabels.propTypes = {
  dataLayerConfig: PropTypes.shape({
    bingPixel: PropTypes.string,
    oribiPixel: PropTypes.string,
    redditPixel: PropTypes.string,
    tiktokPixel: PropTypes.string,
    gaTrackingId: PropTypes.string,
    hubioIdPixel: PropTypes.string,
    yahooPixelId: PropTypes.string,
    facebookPixel: PropTypes.string,
    linkedInPixel: PropTypes.string,
    snapchatPixel: PropTypes.string,
    yahooProjectId: PropTypes.string,
    twitterPurchasePixel: PropTypes.string,
    twitterUniversalPixel: PropTypes.string,
    linkedInConversionPixels: PropTypes.shape({
      purchase: PropTypes.string,
      addToCart: PropTypes.string
    }),
    googleAdsConversions: PropTypes.shape({
      id: PropTypes.string,
      labels: PropTypes.shape({
        purchase: PropTypes.string,
        addToCart: PropTypes.string
      })
    })
  }),
  baseAttribute: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TrackingPixelConversionLabels;