import { Cell, Header } from '../../../../netcapital-components/Table';

function CreditorsHeader(){
  return (
    <Header>
      <Cell>Creditor(s)</Cell>
      <Cell>Outstanding Balance (in USD)</Cell>
      <Cell>Interest Rate</Cell>
      <Cell>Maturity Date</Cell>
      <Cell>Material Terms</Cell>
    </Header>
  );
}

export default CreditorsHeader;