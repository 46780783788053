import Modal from '../../netcapital-components/Modal';
import Session from '../../_session';
import { alertAtom } from '../../_state';
import { history } from '../../_helpers';
import { useAlertActions } from '../../_actions';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

// detect browser to determine browser name for email
function detectBrowser() {
  const userAgent = navigator.userAgent;

  const chrome = new RegExp(/chrome|chromium|crios/i);
  const firefox = new RegExp(/firefox|fxios/i);
  const safari = new RegExp(/safari/i);
  const opra = new RegExp(/opr\//i);
  const edge = new RegExp(/edg/i);
         
  if(chrome.test(userAgent)){
    return 'chrome';
  }
  
  if(firefox.test(userAgent)){
    return 'firefox';
  }
  
  if(safari.test(userAgent)){
    return 'safari';
  }
  
  if(opra.test(userAgent)){
    return 'opera';
  }
  
  if(edge.test(userAgent)){
    return 'edge';
  }
  return 'No browser detection';
}

// defines the error description
function description(response, user){
  const userURL = window.location.href;
  const browser = detectBrowser();
  const object = JSON.parse(JSON.stringify({ ...response }));

  if (object.config?.headers?.Authorization) {
    delete object.config.headers.Authorization;
  }
  
  object.user = { entityKey: user.entityKey, role: user.role, scope: user.scope, userURL, browser };
  
  const emailBody = encodeURIComponent(JSON.stringify(object).toString());

  return `<br />Please, try again. If the error persists, please contact us at <a href="mailto:help@netcapital.com?cc=dev@netcapital.com&subject=Error%20report%20code:%20${object.data?.trackingNumber}&body=${emailBody}">help@netcapital.com<a/> and include the error information presented.<br /><br />Error code: <i>${object.data?.trackingNumber || 'No tracking ID'}</i>`;
}

function Alert() {
  const alert = useRecoilValue(alertAtom);
  const alertActions = useAlertActions();
  const handleClear = alertActions.clear;
  const location = useLocation();
  const user = Session.get('user');

  useEffect(() => {
    const unlisten = history.listen(handleClear);
    return unlisten;
  }, [handleClear]);

  if (!alert || !alert.error || !alert.error.response) return null;

  const { response, message } = alert.error;
  
  if (response.status === 403 && location.pathname !== '/no-permission') {
    window.location.href = `${window.location.protocol}//${window.location.hostname}/no-permission`;
    return null;
  }

  if (response.status === 401) {
    window.location.href = `${process.env.REACT_APP_API_HOST}/v2/auth/login?returnTo=${window.location.href}`;
    return null;
  }

  const messageDescription = `${message}. ${response?.data?.message || ''} <br /> ${description(response, user)}`;

  return (
    <Modal
      open
      onClose={handleClear}
      title={alert.error.title}
      color='error'
      description={messageDescription}
      primaryBtnTitle='Close'
      primaryBtnColor='error'
      handlePrimaryAction={handleClear} 
    />
  );
}

export { Alert };