import Table, { Body } from '../../../../netcapital-components/Table';

import { CONTACT_INFO } from '../../../../static-info/listing-application-forms';
import PreviousNamesHeader from './header';
import PreviousNamesRow from './row';
import _ from 'lodash';
import { fetchContactInfo } from '../../../../_state/application-form';
import produce from 'immer';
import { useAutoSave } from '../../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

function PreviousNamesTable() {
  const [ previousNames, setPreviousNames ] = useRecoilState(fetchContactInfo('previousNames'));
  const savingState = useAutoSave(CONTACT_INFO, 'previousNames', previousNames);

  const addPreviousName = useCallback(() => {
    const newRecord = {
      _key: uuidv4(),
      legalName: '',
    };
    setPreviousNames([...previousNames, newRecord]);
  }, [setPreviousNames, previousNames]);

  const handleChangePreviousName = useCallback((row, attribute, value) => {
    const updatedValues = produce(previousNames, (draft) => {
      _.set(draft[row], attribute, value);
    });
    setPreviousNames([...updatedValues]);
  }, [setPreviousNames, previousNames]);

  const handleRemoveRow = useCallback((index) => {
    const updatedValues = [...previousNames];
    updatedValues.splice(index, 1);
    setPreviousNames([...updatedValues]);
  }, [previousNames, setPreviousNames]);

  if (typeof previousNames === 'undefined') return null;

  return (
    <Table onNewRow={addPreviousName}>
      <PreviousNamesHeader />
      <Body>
        {previousNames && previousNames.map((previousName, index) =>
          <PreviousNamesRow
            previousName={previousName}
            index={index}
            onRemoveRow={handleRemoveRow}
            onChange={handleChangePreviousName}
            error={savingState && savingState.state === 'error'}
            key={previousName._key}
          />
        )}
      </Body>
    </Table>
  );
}

export default PreviousNamesTable;
