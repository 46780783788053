import Question, { Answer } from '../../Question';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { METADATA } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import TrackingPixelConversionLabels from './TrackingPixelConversionLabels';
import { Typography } from '@mui/material';
import _ from 'lodash';
import { fetchMetadata } from '../../../_state/application-form';
import produce from 'immer';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function DataLayerConfig() {
  const [dataLayerConfig, setDataLayerConfig] = useRecoilState(fetchMetadata('dataLayerConfig'));
  useAutoSave(METADATA, 'dataLayerConfig', dataLayerConfig);
  const handleChangeDataLayerConfig = useCallback((attribute) => (e) => {
    const newValue = e.target.value;
    const updatedValues = produce(dataLayerConfig, (draft) => {
      _.set(draft, attribute, newValue);
    });
    setDataLayerConfig(updatedValues);
  }, [dataLayerConfig, setDataLayerConfig]);
  
  if (typeof dataLayerConfig === 'undefined') return null;
  
  return (
    <>
      <Typography variant='h1'>Data Layer Config</Typography>
      <Question>
        Google Analytics Tracking ID
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'gaTrackingId')}
            onChange={handleChangeDataLayerConfig('gaTrackingId')}
            placeholder='G-XXXXXXXXX'
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        Facebook Pixel ID
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'facebookPixel')}
            onChange={handleChangeDataLayerConfig('facebookPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Typography variant='h1'>Google Ads</Typography>
      <Question>
        Conversion ID
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'googleAdsConversions.id')}
            onChange={handleChangeDataLayerConfig('googleAdsConversions.id')}
            placeholder='AW-XXXXXXXXX'
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        Conversion Labels
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='top'
        >
          <TrackingPixelConversionLabels
            dataLayerConfig={dataLayerConfig}
            baseAttribute='googleAdsConversions.labels'
            onChange={handleChangeDataLayerConfig}
          />
        </Answer>
      </Question>
      <Typography variant='h1'>Twitter</Typography>
      <Question>
        Universal Pixel ID
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'twitterUniversalPixel')}
            onChange={handleChangeDataLayerConfig('twitterUniversalPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        Purchase Pixel ID
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'twitterPurchasePixel')}
            onChange={handleChangeDataLayerConfig('twitterPurchasePixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Typography variant='h1'>LinkedIn</Typography>
      <Question>
        Pixel ID
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'linkedInPixel')}
            onChange={handleChangeDataLayerConfig('linkedInPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        Conversion Pixels
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='top'
        >
          <TrackingPixelConversionLabels
            dataLayerConfig={dataLayerConfig}
            baseAttribute='linkedInConversionPixels'
            onChange={handleChangeDataLayerConfig}
          />
        </Answer>
      </Question>
      <Typography variant='h1'>Yahoo/Oath Dot Tag</Typography>
      <Question>
        Project ID
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'yahooProjectId')}
            onChange={handleChangeDataLayerConfig('yahooProjectId')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        Pixel ID
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'yahooPixelId')}
            onChange={handleChangeDataLayerConfig('yahooPixelId')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Typography variant='h1'>Other Vendors</Typography>
      <Question>
        Snapchat Pixel ID
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'snapchatPixel')}
            onChange={handleChangeDataLayerConfig('snapchatPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        Tiktok Pixel ID
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'tiktokPixel')}
            onChange={handleChangeDataLayerConfig('tiktokPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        Reddit Pixel ID
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'redditPixel')}
            onChange={handleChangeDataLayerConfig('redditPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        Bing Pixel ID
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'bingPixel')}
            onChange={handleChangeDataLayerConfig('bingPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        Oribi Pixel ID
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'oribiPixel')}
            onChange={handleChangeDataLayerConfig('oribiPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        HubioID Pixel ID
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'hubioIdPixel')}
            onChange={handleChangeDataLayerConfig('hubioIdPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
    </>
  );
}

export default DataLayerConfig;