import Question, { Answer } from '../../../Question';

import EquitySecuritiesTable from '../EquitySecuritiesTable';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Typography } from '@mui/material';
import styles from '../styles';

function SecurityClasses(){
  return (
    <Question>
      List all classes of equities or securities in order of preference.
      <Typography variant='body1' sx={styles.TablePreface}>Many companies may only have one type, Common Stock. Some companies will also have Preferred Stock, various Classes of stock, or Debt Securities. LLCs generally have Membership Units or Interests.</Typography>
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='top'
      >
        <EquitySecuritiesTable />
      </Answer>
    </Question>
  );
}

export default SecurityClasses;

