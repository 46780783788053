const styles = {
  DateSelector: () => ({
    width: '165px',
  }),
  Checkbox: {
    height: '20px',
    width: '20px',
    padding: 0,
    marginRight: '7px'
  },
  CreditorTableSizes:{
    Name: {
      width: '182px'
    },
    Balance: {
      width: '182px'
    },
    Rate: {
      width: '147px'
    },
    MaturityDate: {
      width: '182px'
    },
    Terms:{
      width: '146px'
    }
  },
  HyperLink: ({ palette }) => ({
    color: palette.primary.dark,
    fontWeight: 600,
    cursor: 'pointer',
  }),
  DialogOption: () => ({
    display: 'flex',
    fontSize: '14px',
    fontWeight: 600,
    cursor: 'pointer',
  }),
  DialogEditOption: ({ palette }) => ({
    color: palette.primary.light,
    textDecorationColor: palette.primary.light,
  }),
  DialogSaveOption: ({ palette }) => ({
    color: palette.success.main,
    textDecorationColor: palette.success.main,
  }),
  DialogIcon: () => ({
    width: '14px',
    marginRight: '5px'
  }),
  DialogContent: () => ({
    width: '100%',
    padding: '40px 50px'
  }),
  DialogIconButton: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  DialogCloseIcon: {
    height: '20px',
    width: '20px'
  },
  OtherCrowdfundingNamesTextarea: {
    marginTop: '15px'
  },
  OfferingDateCellBox: {
    width: '200px'
  },
  ExemptionCellBox: {
    width: '250px'
  },
  SecurityTypeCellBox: {
    width: '200px'
  },
  previousFundingUseOfProceedsCellBox: {
    width: '200px'
  },
  AmountSold: {
    width: '150px'
  }
};

export default styles;