import { Link, AppBar as MUIAppBar, Toolbar, Typography } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '../../netcapital-components/UnderlinedButton';
import { isAdminState } from '../../_state/user';
import styles from './styles';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const DialogBar = () => {
  const { listingKey, offerKey } = useParams();
  const isAdmin = useRecoilValue(isAdminState);
  const LinkJsx = isAdmin
    ? 
    <Link href={listingKey ? `${process.env.REACT_APP_WEB_HOST}/admin/listings/${listingKey}` : `${process.env.REACT_APP_WEB_HOST}/admin/listings`} sx={styles.Link}>
      <Button component='span' sx={styles.BackButton}>
        <ArrowBackIcon />
        {listingKey ? 
          <Typography variant='button' sx={styles.ButtonTypography}>Back To Listing Page</Typography>
          : 
          <Typography variant='button' sx={styles.ButtonTypography}>Back To Listings Page</Typography>
        }
      </Button> 
    </Link>
    : 
    <Link href={offerKey ? `${process.env.REACT_APP_WEB_HOST}/dashboard/offerings/${offerKey}` : `${process.env.REACT_APP_WEB_HOST}/dashboard`} style={styles.Link}>
      <Button component='span' sx={styles.BackButton}>
        <ArrowBackIcon />
        <Typography variant='button' sx={styles.ButtonTypography}>Back To Dashboard</Typography>
      </Button>
    </Link>;

  return (
    <MUIAppBar sx={styles.AppBar}>
      <Toolbar sx={styles.Toolbar}>
        {LinkJsx}
      </Toolbar>
    </MUIAppBar>
  );
};

export default DialogBar;