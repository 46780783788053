const styles = {
  Accordion: ({ palette, breakpoints }) => ({
    position: 'relative',
    zIndex: 1101,
    '& > .MuiCollapse-root': {
      backgroundColor: palette.common.white,
      position: 'absolute',
      marginLeft: '-16px',
      width: '100vw',
      zIndex: '1051',
      [breakpoints.up('sm')]: {
        marginLeft: '-24px',
      },
    },
    '& > .MuiCollapse-entered': {
      boxShadow: '0 3px 8px -4px rgba(0,0,0,0.5)',
    },
    '& .MuiAccordionSummary-root': {
      padding: 0,
      borderBottom: '1px solid rgba(36, 36, 36, 0.2)'
    },
    '& .MuiAccordionDetails-root':{
      padding: '6px 6px 6px 6px',
      [breakpoints.up('sm')]: {
        padding: '12px 24px 24px 24px',
      },
    },
    '& .MuiTypography-gutterBottom':{
      marginLeft: '15px',
      fontWeight: 'bold'
    }
  }),
  AccordionDetails: ({ breakpoints }) => ({
    '& .MuiPaper-root': {
      [breakpoints.up('800')]: {
        width: '65%'
      },
      [breakpoints.up('900')]: {
        width: '60%'
      }
    },
    '& .MuiMenuItem-root': {
      [breakpoints.up('800')]: {
        width: '65%'
      },
      [breakpoints.up('900')]: {
        width: '60%'
      }
    },
    '& .MuiDivider-root': {
      [breakpoints.up('800')]: {
        width: '65%'
      },
      [breakpoints.up('900')]: {
        width: '60%'
      }
    }
  })
};

export default styles;
