import { Cell, Header } from '../../../../netcapital-components/Table';

import { HELP } from '../../../../static-info/index';
import Help from '../../../../netcapital-components/Help';

function PreviousFundingHeader(){
  return (
    <Header>
      <Cell>Date of Offering</Cell>
      <Cell><Help key={1} explanation={HELP['Applicable SEC Exemption']}>Applicable SEC Exemption</Help></Cell>
      <Cell>Securities Offered</Cell>
      <Cell>Amount Sold</Cell>
      <Cell><Help key={2} explanation={HELP['Use of Proceeds']}>Use of Proceeds</Help></Cell>
    </Header>
  );
}

export default PreviousFundingHeader;