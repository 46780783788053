import Table, { Body } from '../../../../netcapital-components/Table';

import AdditionalRisk from './row';
import { RISKS } from '../../../../static-info/listing-application-forms';
import _ from 'lodash';
import { fetchRisks } from '../../../../_state/application-form';
import produce from 'immer';
import { useAutoSave } from '../../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_RISK_DESCRIPTION = 'Please, edit the risk description';

function AdditionalRisks() {
  const [additionalRisks, setAdditionalRisks] = useRecoilState(fetchRisks('additionalRisks'));
  const savingState = useAutoSave(RISKS, 'additionalRisks', additionalRisks);

  const handleChange = useCallback((index) => (value) => {
    const updatedValues = produce(additionalRisks, (draft) => {
      _.set(draft[index], 'riskDescription', value);
    });
    setAdditionalRisks([...updatedValues]);
  }, [additionalRisks, setAdditionalRisks]);

  const handleClickRemove = useCallback((index) => () => {
    const copyAdditionalRisks = [...additionalRisks];
    copyAdditionalRisks.splice(index, 1);
    setAdditionalRisks([...copyAdditionalRisks]);
  }, [additionalRisks, setAdditionalRisks]);
  
  const handleAddRisk = useCallback(() => {
    const copyAdditionalRisks = [...additionalRisks];
    copyAdditionalRisks[additionalRisks.length] = {
      _key: uuidv4(),
      riskDescription: DEFAULT_RISK_DESCRIPTION,
    };
    setAdditionalRisks([...copyAdditionalRisks]);
  }, [additionalRisks, setAdditionalRisks]);
  
  if (typeof additionalRisks === 'undefined') return null;

  return (
    <Table onNewRow={handleAddRisk} error={savingState && savingState.state === 'error'}>
      <Body>
        {additionalRisks && additionalRisks.map((risk, index) => (
          <AdditionalRisk
            value={risk.riskDescription}
            onClickRemove={handleClickRemove(index)}
            onChange={handleChange(index)}
            key={risk._key}
            defaultValue={DEFAULT_RISK_DESCRIPTION}
          />
        ))}
      </Body>
    </Table>
  );
}

export default AdditionalRisks;
