import Question, { Answer } from '../../Question';

import FileField from '../FileField';
import { fetchFinancialStatementsFile } from '../../../_state/application-form';

function CashFlowStatement(){
  return (
    <Question>
      Upload your Cashflow Statement
      <Answer>
        <FileField usage='CASH_FLOW' state={fetchFinancialStatementsFile} />
      </Answer>
    </Question>
  );
}

export default CashFlowStatement;