import Question, { Answer } from '../../../Question';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FiscalYearsTable from './table';
import { fetchContactInfo } from '../../../../_state/application-form';
import { useRecoilValue } from 'recoil';

function FiscalYears(){
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));

  return (
    <Question>
      {`Add ${doingBusinessAs || 'your company'}’s financial reports below.`}
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='top'
      >
        <FiscalYearsTable />
      </Answer>
    </Question>
  );
}

export default FiscalYears;