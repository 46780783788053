const styles = {
  AppBar: ({ palette }) => ({
    display: 'block',
    position: 'static',
    backgroundColor: palette.common.white,
    boxShadow: 'none',
    marginBottom: '15px',
  }),
  BackButton: ({ palette }) => ({
    '& .MuiSvgIcon-root':{
      color: palette.primary.dark,
    },
    padding: '5px',
  }),
  ButtonTypography: ({ palette }) => ({
    color: palette.primary.dark,
    marginLeft: '5px',
  }),
  Link: {
    textDecoration: 'none',
  },
  Toolbar: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '65px',
  }),
};

export default styles;