import Question, { Answer } from '../../Question';

import FileField from '../FileField';
import { fetchFinancialStatementsFile } from '../../../_state/application-form';

function IncomeStatement(){
  return (
    <Question>
      Upload your Income Statement
      <Answer>
        <FileField usage='INCOME' state={fetchFinancialStatementsFile} />
      </Answer>
    </Question>
  );
}

export default IncomeStatement;