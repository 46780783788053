import Question, { Answer } from '../../Question';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { METADATA } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { fetchMetadata } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function MarketSize() {
  const [market, setMarket] = useRecoilState(fetchMetadata('market'));
  const savingState = useAutoSave(METADATA, 'market', market);
  const handleChangeMarket = useCallback(e => setMarket(e.target.value), [setMarket]);
  
  return (
    <Question>
      Market size (e.g. $2T real estate market)
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='bottom'
      >
        <TextField
          value={market || ''}
          onChange={handleChangeMarket}
          ownerState={{ subVariant: 'admin' }}
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}

export default MarketSize;
