import { Cell, Header } from '../../../../netcapital-components/Table';

import { memo } from 'react';

function PrincipalOwnersTableHeader() {
  return (
    <Header>
      <Cell>Name of Holder</Cell>
      <Cell>Number of Securities Now Held</Cell>
      <Cell>Class of Securities</Cell>
      <Cell>Percentage of Voting Power</Cell>
    </Header>
  );
}

export default memo(PrincipalOwnersTableHeader);
